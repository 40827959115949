<template>
  <div>

    <!-- ↓ ContentTypes.result -->
    <div v-if="contentType==ContentTypes.result" class="RD_outer_card_wrapper">
      <div class="outer-card">
        <div class="card border-secondary">

          <!-- card-header -->
          <component v-if="examination&&student"
                      :is="headerComponent" :isDebug="isDebug" :calledBy="headerCalledBy"
                      :examination="examination" :student="student"
                      :examPartSetId="$route.params.examPartSetId" :currentExamPart="currentMasterExaminationPart/*結果表示時はnull*/"
          />

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <template v-if="student">

              <ResultHeaderFooterComponent :isDebug="isDebug"
                                            :contentType="contentType" :scExStudentExamPartSet="examPartSet" :token="token" :showLogoutButton="true"
                                            @goto-home="$router.push({ name: 'home' })" @logout="onClickLogoutButton"
                                            @show-result="onShowResult" @show-desc1="onShowDesc1" @show-desc2="onShowDesc2"
              />

              <!-- row * N  -->
              <component :is="personalReportComponent" :schoolExamStudent="student" :scExStudentExamPartSet="examPartSet" />

              <!-- XDには存在しない
              <ResultHeaderFooterComponent :isDebug="isDebug"
                                            :contentType="contentType" :scExStudentExamPartSet="examPartSet" :token="token" :showLogoutButton="false"
                                            @goto-home="$router.push({ name: 'home' })" @logout="onClickLogoutButton"
                                            @show-result="onShowResult" @show-desc1="onShowDesc1" @show-desc2="onShowDesc2"
              />
              -->

            </template><!-- v-if="student" -->

            <FooterComponent calledBy="Result" />

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- outer-card -->
    </div><!-- RD_outer_card_wrapper -->
    <!-- ↑ ContentTypes.result -->

    <!-- ↓ ContentTypes.desc1, desc2 -->
    <div v-else-if="[ ContentTypes.desc1, ContentTypes.desc2 ].includes(contentType)" class="row mt-3">
      <div class="col-12 col-md-auto mx-md-auto col-lg-10 col-xl-7 outer-card"><!-- 問題画面の幅を決める重要なclass群 -->
        <div class="card border-secondary">

          <!-- card-header -->
          <component v-if="examination&&student"
                      :is="headerComponent" :isDebug="isDebug" :calledBy="headerCalledBy"
                      :examination="examination" :student="student"
                      :examPartSetId="$route.params.examPartSetId" :currentExamPart="currentMasterExaminationPart/*結果表示時はnull*/"
          />

          <div class="card-body px-3 px-md-5"><!-- 問題画面の幅を決める重要なclass群 -->

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <template v-if="student">

              <ResultHeaderFooterComponent :isDebug="isDebug"
                                            :contentType="contentType" :scExStudentExamPartSet="examPartSet" :token="token" :showLogoutButton="true"
                                            @goto-home="$router.push({ name: 'home' })" @logout="onClickLogoutButton"
                                            @show-result="onShowResult" @show-desc1="onShowDesc1" @show-desc2="onShowDesc2"
              />

              <div v-if="isDebug" class="debug_str">検査のメインコンポーネント（{{ examPartMainComponent }}）</div>
              <component :is="examPartMainComponent" :isDebug="isDebug"
                          :examinationPart="currentMasterExaminationPart" :currentPageNo="currentPageNo"
                          :canEdit="false" :showDescription="true"
                          v-model="currentMasterExaminationPart.all_page_params_set"
                          @move-page="onMovePage" @move-next-page="onMovePage(currentPageNo + 1)" @move-prev-page="onMovePage(currentPageNo - 1)"
              /><!-- includes row & col-12  -->

              <ResultHeaderFooterComponent :isDebug="isDebug"
                                            :contentType="contentType" :scExStudentExamPartSet="examPartSet" :token="token" :showLogoutButton="false"
                                            @goto-home="$router.push({ name: 'home' })" @logout="onClickLogoutButton"
                                            @show-result="onShowResult" @show-desc1="onShowDesc1" @show-desc2="onShowDesc2"
              />

            </template><!-- v-if="student" -->

            <FooterComponent />

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->
    <!-- ↑ ContentTypes.desc1, desc2 -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import HeaderComponent_TG from '../components/TG/HeaderComponent';
import HeaderComponent_TAMA from '../components/TAMA/HeaderComponent';
import FooterComponent from '../components/FooterComponent.vue';
import ResultHeaderFooterComponent from '../components/ResultHeaderFooterComponent.vue';
import PersonalReport_TG from '../../common/components/personal_report/TG/PersonalReport';
import PersonalReport2_TG from '../../common/components/personal_report/TG/PersonalReport2';
import PersonalReport_TAMA from '../../common/components/personal_report/TAMA/PersonalReport';
import PersonalReport2_TAMA from '../../common/components/personal_report/TAMA/PersonalReport2';
// ↓ 全ての検査コンポーネントをimportする ※ components への記載も忘れないこと！！
// ※ コンポーネント名は examination_parts.main_component_name に定義されている
import tg1_a_t1       from '../../common/components/examination_parts/TG1/Ａタイプ/検査１';
import tg1_a_t2       from '../../common/components/examination_parts/TG1/Ａタイプ/検査２';
import tg1_b_t1       from '../../common/components/examination_parts/TG1/Ｂタイプ/検査１';
import tg1_b_t2       from '../../common/components/examination_parts/TG1/Ｂタイプ/検査２';
import tg1_pa_kei1_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数１/検査１';
import tg1_pa_kei2_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数２/検査１';
import tg1_pa_kei3_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数３/検査１';
import tg1_pa_kei4_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数４/検査１';
import tg1_pa_gen_t1  from '../../common/components/examination_parts/TG1/練習Ａ_言語/検査１';
import tg1_pb_gk_t1   from '../../common/components/examination_parts/TG1/練習Ｂ_言語＋計数/検査１';
import tama1_a_t1     from '../../common/components/examination_parts/TAMA1/Ａタイプ/検査１';
import tama1_a_t2     from '../../common/components/examination_parts/TAMA1/Ａタイプ/検査２';
import tama1_b_t1     from '../../common/components/examination_parts/TAMA1/Ｂタイプ/検査１';
import tama1_b_t2     from '../../common/components/examination_parts/TAMA1/Ｂタイプ/検査２';
import tama1_c_t1     from '../../common/components/examination_parts/TAMA1/Ｃタイプ/検査１';
import tama1_c_t2     from '../../common/components/examination_parts/TAMA1/Ｃタイプ/検査２';
import tama1_p_hy_t1  from '../../common/components/examination_parts/TAMA1/練習_表の読み取り/検査１';
import tama1_p_ha_t1  from '../../common/components/examination_parts/TAMA1/練習_表の穴埋め/検査１';
import tama1_p_sg_t1  from '../../common/components/examination_parts/TAMA1/練習_四則逆算/検査１';
import tama1_p_eg_t1  from '../../common/components/examination_parts/TAMA1/練習_英語GAB/検査１';
// ↑
const LogHeader = 'Result.vue';
const ContentTypes = { // ResultHeaderFooterと整合注意 ※冗長だがこの２ヶ所だけのはずなのでとりあえず放置
  result: '個人結果表',
  desc1: '検査１ 解説',
  desc2: '検査２ 解説',
};

export default {
  PageTitle: '個人結果表',
  components: {
    HeaderComponent_TG,
    HeaderComponent_TAMA,
    FooterComponent,
    ResultHeaderFooterComponent,
    PersonalReport_TG, PersonalReport2_TG,
    PersonalReport_TAMA, PersonalReport2_TAMA,
    tg1_a_t1, tg1_a_t2,
    tg1_b_t1, tg1_b_t2,
    tg1_pa_kei1_t1, tg1_pa_kei2_t1, tg1_pa_kei3_t1, tg1_pa_kei4_t1, tg1_pa_gen_t1, tg1_pb_gk_t1,
    tama1_a_t1, tama1_a_t2,
    tama1_b_t1, tama1_b_t2,
    tama1_c_t1, tama1_c_t2,
    tama1_p_hy_t1, tama1_p_ha_t1, tama1_p_sg_t1, tama1_p_eg_t1,
  },
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      token: window.token, // see https://jwt-auth.readthedocs.io/en/develop/quick-start/ > Authenticated requests > Query string parameter
      resourceApi,
      schoolExamination: null,
      examination: null,
      student: null,
      ContentTypes,
      contentType: ContentTypes.result,
      currentExamPartIdx: null,
      currentPageNo: null,
      isDebug: false, // デバッグ表示用（本番false）
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        // 全ての検査が終了していない場合はhomeにリダイレクト
        // 本テスト（復習を含む）と１回目の練習は if (!this.examPartSet.finished_at) だけで判定できるが、
        // ２回目以降の練習版は開始時にfinished_atを消去してしまう（が、１回目の結果は持っている状態）ので、上記判定条件が使えない
        // なのでfinished_atではなく「個人結果表データを持っているかどうか」で判定するようにした（持っていなければhomeにリダイレクト）
        if (!this.examPartSet.has_personal_report_set) {
          console.log('redirecting result to home');
          this.$router.push({ name: 'home' });
          return;
        }

        // 検査１・・・検査Ｎの正解・解説をall_page_params_setに埋め込み
        // 【旧】210806MOD desc_answer導入に伴い、第２引数を追加した
        const examPartsLength = this.examPartSet.master_exam_parts.length;
        for (let i = 0; i < examPartsLength; i++) {
          if (!this.examPartSet.master_exam_parts[i].is_dummy_exam_part) { // ダミー（＝総合）でない？
            this.reflectExamPartEvaluationSet(this.examPartSet.master_exam_parts[i], this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[i]);
          }
        }

        if (this.$route.params.showDescAtFirst) {
          this.onShowDesc1();
        } else {
          this.onShowResult();
        }

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    headerComponent() {
      if (this.examination) {
        switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
          case 'TG1':   return 'HeaderComponent_TG';
          case 'TAMA1': return 'HeaderComponent_TAMA';
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    headerCalledBy() {
      switch (this.contentType) {
        case ContentTypes.result: return this.examPartSet.is_practice_test ? 'Result_P' : 'Result';
        case ContentTypes.desc1:  return 'Desc1';
        case ContentTypes.desc2:  return 'Desc2';
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    personalReportComponent() {
      if (this.examination && this.examPartSet) {
        if (this.examPartSet.is_practice_test) { // 練習テスト？
          switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
            case 'TG1':   return 'PersonalReport2_TG';
            case 'TAMA1': return 'PersonalReport2_TAMA';
          }
        } else { // 本試験or復習テスト？
          switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
            case 'TG1':   return 'PersonalReport_TG';
            case 'TAMA1': return 'PersonalReport_TAMA';
          }
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    examPartSet() { return this.student&&this.student.organized_exam_part_sets? this.student.organized_exam_part_sets[this.$route.params.examPartSetId]: null; },
    currentMasterExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.master_exam_parts[this.currentExamPartIdx]: null; },
    currentMyExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[this.currentExamPartIdx]: null; },
    examPartMainComponent() { return this.currentMasterExaminationPart? this.currentMasterExaminationPart.main_component_name: null; },
    isLastPage() { return this.currentMasterExaminationPart? (this.currentPageNo == this.currentMasterExaminationPart.page_count ): false; },
  },
  methods: {
    reflectExamPartEvaluationSet(examinationPart, myExaminationPart) { // 210806MOD 引数にmyExaminationPartを追加した
      // 正解＆解説一式（evaluation_setの各answerとdescription）をall_page_params_setに埋め込む ※admin/views/master/examination_parts/Edit.vueのafterCreatedGetThen()と似たロジック
      // 210806MOD 解説時は問題部のチェックボックスには受験者の回答を表示する必要があるので（解説側の正解表示は従来通り）、解説側の正解を desc_answer として分離した
      console.log(`[${LogHeader}] reflectExamPartEvaluationSet() START`);
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        let pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            let questionParams = pageParams[questionKey];
            // console.log(myExaminationPart.result_set); // デバッグ用（本番コメントアウト）
            questionParams.answer = myExaminationPart.result_set.answer_set[questionKey] ?? []; // 210806MOD 受験者の回答内容をセットする（従来は正解をセットしていた） // 211014MOD 練習版のmyExaminationPart.answer_setは維持できないのでmyExaminationPart.result_set.answer_setに変更
            questionParams.desc_answer = examinationPart.evaluation_set[questionKey].answer ?? []; // 210806ADD
            questionParams.description = examinationPart.evaluation_set[questionKey].description ?? '';
            // console.log(`[${pageKey}][${questionKey}] answer=[${examinationPart.all_page_params_set[pageKey][questionKey].answer}] desc_answer=[${examinationPart.all_page_params_set[pageKey][questionKey].desc_answer}]`); // デバッグ用（本番コメントアウト）
            // console.log(`[${pageKey}][${questionKey}].descrption=[${examinationPart.all_page_params_set[pageKey][questionKey].description}]`); // デバッグ用（本番コメントアウト）
          }
        }
      }
      console.log(`[${LogHeader}] reflectExamPartEvaluationSet() END`, examinationPart.all_page_params_set);
    },
    onPopState() {
      console.log(`[${LogHeader}] onPopState() CALLED`);
      window.history.pushState(null, null, null);
      alert('ブラウザの「戻る」は使用できません。');
    },
    onMovePage(newPageNo) {
      console.log(`[${LogHeader}] onMovePage(newPageNo=[${newPageNo}]) CALLED`);
      this.currentPageNo = newPageNo;
      window.scrollTo(0, 0);
    },
    onShowResult() {
      console.log(`[${LogHeader}] onShowResult() CALLED`);
      this.contentType = ContentTypes.result;
      this.currentExamPartIdx = null;
      this.currentPageNo = null;
      window.scrollTo(0, 0);
    },
    onShowDesc1() {
      console.log(`[${LogHeader}] onShowDesc1() CALLED`);
      this.contentType = ContentTypes.desc1;
      this.currentExamPartIdx = 0;
      this.currentPageNo = 1;
      window.scrollTo(0, 0);
    },
    onShowDesc2() {
      console.log(`[${LogHeader}] onShowDesc2() CALLED`);
      this.contentType = ContentTypes.desc2;
      this.currentExamPartIdx = 1;
      this.currentPageNo = 1;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"

row:not(.RD_outer_card_wrapper)
  .outer-card
    width: 80rem
  .card-body
    +mq(max_575)
      padding: 1.0rem 0.5rem
  #examination_parts_wrapper
    width: 100%

// ↓ 2024-02 Re-Designed by 「TAMA1 結果画面 Bootstrap 4 Grid Template - All 5 Sizes.xd」

.RD_outer_card_wrapper

  .card-body

    // padding-top : HeaderComponent下端とResultHeaderFooterComponent上端の間の余白に相当
    // padding-bottom : FooterComponent下端とcard枠線の間の余白に相当
    +paddingY(10.1px, 53.7px)
    +mq_min_sm
      +paddingY(11.5px, 66.6px)
    +mq_min_md
      +paddingY(13.8px, 62.2px)
    +mq_min_lg
      +paddingY(19.8px, 75.7px)
    +mq_min_xl
      +paddingY(34.8px, 110.3px)

    // X方向の余白はブロック／セクション毎に記述する

  // .card-body

// ↑ 2024-02 Re-Designed

</style>
