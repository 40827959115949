import StatusMessage from '../components/StatusMessageComponent.vue';
import {
  HTTP_STATUS,
} from '../util';

export default {
  components: {
    StatusMessage,
  },
  data() {
    console.log('[mixins/common] data() CALLED');
    return {
      DefaultTimeout: 1200,
    };
  },
  // created () {
  //   console.log('[mixins/common] created() CALLED');
  // },
  mounted() {
    console.log(`[mixins/common] mounted() CALLED`);
    let appName = `WEBテスト`;
    let { PageTitle, DataTitle } = this.$options;
    if (PageTitle) {
      document.title = `${PageTitle} - ${appName}`;
    } else if (DataTitle) {
      document.title = `${DataTitle} - ${appName}`;
    } else {
      document.title = `${appName}`;
    }

    //「戻る」防止
    if (typeof this.onPopState === 'function') {
      window.history.pushState(null, null, null);
      console.log(`[mixins/common] mounted() addEventListener(popstate)`);
      window.addEventListener('popstate', this.onPopState);
    }

    //タブクローズ防止
    if (typeof this.onBeforeUnload === 'function') {
      console.log(`[mixins/common] mounted() addEventListener(beforeunload)`);
      window.addEventListener('beforeunload', this.onBeforeUnload);
    }

    // リサイズ検知用
    if (typeof this.onResize === 'function') {
      console.log(`[mixins/common] mounted() addEventListener(resize)`);
      window.addEventListener('resize', this.onResize);
    }
  },
  // updated() {
  //   console.log(`[mixins/common] updated() CALLED`);
  // },
  destroyed() {
    console.log(`[mixins/common] destroyed() CALLED`);
    if (typeof this.onPopState === 'function') {
      window.removeEventListener('popstate', this.onPopState);
    }
    if (typeof this.onBeforeUnload === 'function') {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }
    if (typeof this.onResize === 'function') {
      window.removeEventListener('resize', this.onResize);
    }
  },
  // beforeRouteLeave(to, from, next) {
  //   console.log(`[mixins/common] beforeRouteLeave() CALLED`);
  //   console.log('to ↓', to);
  //   next();
  // },
  computed: {
    isLoggedIn() { return this.$store.getters['auth/isLoggedIn']; },
    authUserId() { return this.$store.getters['auth/userId']; },
    authUserName() { return this.$store.getters['auth/userName']; },
    authUser() { return this.$store.getters['auth/user']; },
    httpStatus() { return this.$store.state.error.code; },
    isHttpStatusOK() { return this.$store.getters['error/isHttpStatusOK']; },
    errors() { return this.$store.state.error.errors; },
    hasErrors() { return this.$store.getters['error/hasErrors']; },
    isAvailable() { return this.$store.getters['status/isAvailable']; },
  },
  methods: {
    hasError(name) { return this.errors && this.errors[name]; },
    resetError() {
      console.log(`[mixins/common] resetError() CALLED`);
      this.$store.commit('error/reset');
    },
    setErrorMessage(message) {
      console.log(`[mixins/common] setErrorMessage(${message}) CALLED`);
      this.$store.commit('error/setMessage', message);
    },
    setResult(errorResponse) {
      console.log(`[mixins/common] setResult() CALLED`, errorResponse);
      this.$store.commit('error/setResult', errorResponse);
    },
    resetStatus() {
      console.log(`[mixins/common] resetStatus() CALLED`);
      this.$store.commit('status/reset');
    },
    setLoadingStatus(value, message) {
      console.log(`[mixins/common] setLoadingStatus(${value},${message}) CALLED`);
      this.$store.commit('status/setLoading', {value, message});
    },
    setSavingStatus(value, message) {
      console.log(`[mixins/common] setSavingStatus(${value},${message}) CALLED`);
      this.$store.commit('status/setSaving', {value, message});
    },
    setStatusMessage(message) {
      console.log(`[mixins/common] setStatusMessage(${message}) CALLED`);
      this.$store.commit('status/setMessage', message);
    },
    setTimeoutToResetErrorAndStatus(timeoutMs) {
      console.log('[mixins/common] setTimeoutToResetErrorAndStatus() START');
      setTimeout(() => {
          console.log('[mixins/common] setTimeoutToResetErrorAndStatus() TIMED OUT');
          this.resetError();
          this.resetStatus();
        }, (timeoutMs&&timeoutMs>0)? timeoutMs: this.DefaultTimeout);
      console.log('[mixins/common] setTimeoutToResetErrorAndStatus() END');
    },
    setTimeoutToGoBack(count, timeoutMs) {
      console.log('[mixins/common] setTimeoutToGoBack() START');
      setTimeout(() => {
          console.log('[mixins/common] setTimeoutToGoBack() TIMED OUT');
          this.resetError();
          this.resetStatus();
          this.$router.go((count&&count<0)? count: -1);
        }, (timeoutMs&&timeoutMs>0)? timeoutMs: this.DefaultTimeout);
      console.log('[mixins/common] setTimeoutToGoBack() END');
    },
    setTimeoutToGoHome(timeoutMs) {
      console.log('[mixins/common] setTimeoutToGoHome() START');
      setTimeout(() => {
          console.log('[mixins/common] setTimeoutToGoHome() TIMED OUT');
          this.resetError();
          this.resetStatus();
          this.$router.push({name: 'home'});
        }, (timeoutMs&&timeoutMs>0)? timeoutMs: this.DefaultTimeout);
      console.log('[mixins/common] setTimeoutToGoHome() END');
    },
    setTimeoutToGoToLogin(timeoutMs) {
      console.log('[mixins/common] setTimeoutToGoToLogin() START');
      setTimeout(() => {
          console.log('[mixins/common] setTimeoutToGoToLogin() TIMED OUT');
          this.resetError();
          this.resetStatus();
          this.$router.push({name: 'login'});
        }, (timeoutMs&&timeoutMs>0)? timeoutMs: this.DefaultTimeout);
      console.log('[mixins/common] setTimeoutToGoToLogin() END');
    },
    setTimeoutToReload(timeoutMs, force) {
      console.log('[mixins/common] setTimeoutToReload() START');
      setTimeout(() => {
          console.log('[mixins/common] setTimeoutToReload() TIMED OUT');
          this.resetError();
          this.resetStatus();
          this.$router.go({path: this.$router.currentRoute.path, force: force? force: false});
        }, timeoutMs? timeoutMs: this.DefaultTimeout);
      console.log('[mixins/common] setTimeoutToReload() END');
    },
    forceUpdate() {
      console.log('[mixins/common] forceUpdate() START');
      this.$forceUpdate();
      console.log('[mixins/common] forceUpdate() END');
    },
    reload() {
      console.log('[mixins/common] reload() START');
      this.$router.go({path: this.$router.currentRoute.path, force: true});
      console.log('[mixins/common] reload() END');
    },
    myAlert(message) {
      alert(message);
    },
    myConfirm(message) {
      return confirm(message);
    },
    onCancel() {
      console.log(`[mixins/common] onCancel() START`);
      scrollTo(0, 0);
      this.setLoadingStatus(true);
      this.$router.go(-1);
      console.log(`[mixins/common] onCancel() END`);
    },
    async onClickLogoutButton() {
      console.log(`[mixins/common] onClickLogoutButton() START`);
      await this.$store.dispatch('auth/logout');
      if (this.isHttpStatusOK) {
        this.$router.push({ name: 'login' });
      }
      console.log(`[mixins/common] onClickLogoutButton() END`);
    },
    hideModal(modelId) {
      $(`#${modelId}`).modal('hide');
    },
    notifyArrayChangeToVue(arr, index) {
      arr.splice(index, 1, arr[index]);//vueに配列の変更を検出させる為のトリック
    },
    makeFilteredObject(srcObject, filterProps) {
      // console.log(`[mixins/common] makeFilteredObject() srcObject=`, srcObject);
      // console.log(`[mixins/common] makeFilteredObject() filterProps=`, filterProps);
      let newObject = {};
      for (let filterProp of filterProps) {
        newObject[filterProp] = srcObject[filterProp];
      }
      // console.log(`[mixins/common] makeFilteredObject() newObject=`, newObject);
      return newObject;
    },
    makeFilteredObjects(srcObjects, filterProps) {
      let newObjects = [];
      for (let srcObject of srcObjects) {
        newObjects.push(this.makeFilteredObject(srcObject, filterProps));
      }
      return newObjects;
    },
    getYYYYMMDDStr(dt, delimiter) {
      if (!dt) {
        dt = new Date();
      }
      let year = dt.getFullYear();
      let month = ('00' + (dt.getMonth()+1)).slice(-2);
      let day = ('00' + dt.getDate()).slice(-2);
      if (!delimiter) {
        delimiter = '-';
      }
      return `${year}${delimiter}${month}${delimiter}${day}`;
    },
    trimWhiteSpace(str) {
      if (typeof str !== 'string') {
        return '';
      }
      return str.trim();
    },
    wideAlphaNumToHalf(str) {
      if (typeof str !== 'string') {
        return '';
      }
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0/*65248*/);
      });
    },
    removeComma(str) {
      if (typeof str !== 'string') {
        return '';
      }
      return str.replace(/[，、,､]/g, '');
    },
    halfKanaToWide(str) {
      var kanaMap = {
        'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
        'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
        'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
        'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
        'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
        'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
        'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
        'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
        'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
        'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
        'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
        'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
        'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
        'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
        'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
        'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
        'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
        'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
        '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
      };
      var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
      return str.replace(reg, function (match) { return kanaMap[match]; })
                .replace(/ﾞ/g, '゛')
                .replace(/ﾟ/g, '゜');
    },
    circledInt(num) { // 1-50の丸数字に対応
      if (1 <= num && num <= 20) {
        return String.fromCharCode('①'.charCodeAt(0) + num - 1);
      } else if (num <= 35) {
        return String.fromCharCode('㉑'.charCodeAt(0) + num - 21);
      } else if (num <= 50) {
        return String.fromCharCode('㊱'.charCodeAt(0) + num - 36);
      }
      return `${num}`;
    },
    getCurrentGridClass() { // see also common/sass/_base.sass
      const width = window.innerWidth;
      let gridClass= 'xs'
      if (width >= 576) {
        gridClass = 'sm';
      }
      if (width >= 768) {
        gridClass = 'md';
      }
      if (width >= 992) {
        gridClass = 'lg';
      }
      if (width >= 1200) {
        gridClass = 'xl';
      }
      console.log(`[mixins/common] getCurrentGridClass() width=[${width}] → gridClass=[${gridClass}]`);
      return gridClass;
    },
  }, // methods
  watch: {
    httpStatus: {
      handler(newValue, oldValue) {
        console.log(`[mixins/common] watch:httpStatus([${oldValue}]→[${newValue}]) CALLED`);
        switch (newValue) {
          case HTTP_STATUS.UNAUTHORIZED:
            console.error(`[mixins/common] httpStatus is UNAUTHORIZED`);
            this.$store.commit('auth/setUser', null);
            this.setErrorMessage(`セッションが切れました。`);
            setTimeout(() => {
              this.$router.push({ name: 'login' });
            }, this.DefaultTimeout);
            break;
          case HTTP_STATUS.NOT_FOUND:
            console.error(`[mixins/common] httpStatus is NOT_FOUND`);
            this.setErrorMessage(`Not Found`);
            // setTimeout(() => {
            //   this.$router.push({ name: 'login' });
            // }, this.DefaultTimeout);
            break;
          case HTTP_STATUS.INTERNAL_SERVER_ERROR:
            console.error(`[mixins/common] httpStatus is INTERNAL_SERVER_ERROR`);
            this.setErrorMessage(`Internal Server Error`);
            // setTimeout(() => {
            //   this.$router.push({ name: 'login' });
            // }, this.DefaultTimeout);
            break;
          case HTTP_STATUS.TOKEN_MISMATCH:
            //XXX トークンリフレッシュが必要かも知れない
            console.error(`[mixins/common] httpStatus is TOKEN_MISMATCH`);
            this.$store.commit('auth/setUser', null);
            this.setErrorMessage(`セッションが切れました。`);
            setTimeout(() => {
              this.$router.push({ name: 'login' });
            }, this.DefaultTimeout);
            break;
        }
      },
      immediate: true,
    },
  },
  filters: {
    YmdHisToYmd(value/*YYYY-MM-DD HH:MM:SS*/) {
      if (!value) { return ''; }
      return value.substring(0, 10);
    },
  },
}
