<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 col-md-auto mx-md-auto col-lg-10 col-xl-7 outer-card"><!-- 問題画面の幅を決める重要なclass群 -->
        <div class="card border-secondary">

          <component v-if="examination&&student&&currentMasterExaminationPart"
                      :is="headerComponent" :isDebug="isDebug" calledBy="Exam"
                      :examination="examination" :student="student"
                      :examPartSetId="$route.params.examPartSetId" :currentExamPart="currentMasterExaminationPart"
          /><!-- includes card-header -->

          <div class="card-body px-3 px-md-5"><!-- 問題画面の幅を決める重要なclass群 -->

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <!-- 問題画面 -->
            <template v-if="!(showIntermediateComponent||showFinishComponent)">

              <template v-if="currentMasterExaminationPart&&currentPageNo">

                <div v-if="isDebug" class="debug_str">検査のメインコンポーネント（{{ examPartMainComponent }}） examPartRemainingSec=[{{ examPartRemainingSec }}]</div>
                <component :is="examPartMainComponent" ref="refExamPartComponent" :isDebug="isDebug"
                            :examinationPart="currentMasterExaminationPart" :currentPageNo="currentPageNo" :examPartRemainingSec="examPartRemainingSec"
                            :canEdit="false" :showDescription="false"
                            v-model="currentMasterExaminationPart.all_page_params_set" @input="onInput"
                            @move-page="onMovePage" @move-next-page="onMovePage(currentPageNo + 1)" @move-prev-page="onMovePage(currentPageNo - 1)"
                            @finish-exam-part="isLastExamPart ? onFinishAll1() : onMoveToNextExamPart1()"
                /><!-- includes row & col-12  -->

              </template>

            </template><!-- v-if="!(showIntermediateComponent||showFinishComponent)" -->

            <!-- 検査終了確認画面 -->
            <component :is="finishComponent" v-if="showFinishComponent" :isDebug="isDebug"
            :scExStudentExamPartSet="examPartSet"  :isTimedOut="isTimedOut"
                        @finish="isLastExamPart ? onFinishAll2(): onMoveToNextExamPart2()"
                        @cancel="() => { resetError(); resetStatus(); showFinishComponent=false; }"
            /><!-- includes row -->

            <!-- 次の検査前の案内画面 -->
            <component :is="intermediateComponent" v-if="showIntermediateComponent" :isDebug="isDebug"
                        :scExStudentExamPartSet="examPartSet" :isTimedOut="isTimedOut"
                        @start-next="onMoveToNextExamPart3()"
            /><!-- includes row -->

            <FooterComponent :showLogo="false" />

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import HeaderComponent_TG from '../components/TG/HeaderComponent';
import HeaderComponent_TAMA from '../components/TAMA/HeaderComponent';
import FooterComponent from '../components/FooterComponent.vue';
import ExamIntermediateComponent_TG from '../components/TG/ExamIntermediateComponent.vue';
import ExamIntermediateComponent_TAMA from '../components/TAMA/ExamIntermediateComponent.vue';
import ExamFinishComponent_TG from '../components/TG/ExamFinishComponent.vue';
import ExamFinishComponent_TAMA from '../components/TAMA/ExamFinishComponent.vue';
// ↓ 全ての検査コンポーネントをimportする ※ components への記載も忘れないこと！！
// ※ コンポーネント名は examination_parts.main_component_name に定義されている
import tg1_a_t1       from '../../common/components/examination_parts/TG1/Ａタイプ/検査１';
import tg1_a_t2       from '../../common/components/examination_parts/TG1/Ａタイプ/検査２';
import tg1_b_t1       from '../../common/components/examination_parts/TG1/Ｂタイプ/検査１';
import tg1_b_t2       from '../../common/components/examination_parts/TG1/Ｂタイプ/検査２';
import tg1_pa_kei1_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数１/検査１';
import tg1_pa_kei2_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数２/検査１';
import tg1_pa_kei3_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数３/検査１';
import tg1_pa_kei4_t1 from '../../common/components/examination_parts/TG1/練習Ａ_計数４/検査１';
import tg1_pa_gen_t1  from '../../common/components/examination_parts/TG1/練習Ａ_言語/検査１';
import tg1_pb_gk_t1   from '../../common/components/examination_parts/TG1/練習Ｂ_言語＋計数/検査１';
import tama1_a_t1     from '../../common/components/examination_parts/TAMA1/Ａタイプ/検査１';
import tama1_a_t2     from '../../common/components/examination_parts/TAMA1/Ａタイプ/検査２';
import tama1_b_t1     from '../../common/components/examination_parts/TAMA1/Ｂタイプ/検査１';
import tama1_b_t2     from '../../common/components/examination_parts/TAMA1/Ｂタイプ/検査２';
import tama1_c_t1     from '../../common/components/examination_parts/TAMA1/Ｃタイプ/検査１';
import tama1_c_t2     from '../../common/components/examination_parts/TAMA1/Ｃタイプ/検査２';
import tama1_p_hy_t1  from '../../common/components/examination_parts/TAMA1/練習_表の読み取り/検査１';
import tama1_p_ha_t1  from '../../common/components/examination_parts/TAMA1/練習_表の穴埋め/検査１';
import tama1_p_sg_t1  from '../../common/components/examination_parts/TAMA1/練習_四則逆算/検査１';
import tama1_p_eg_t1  from '../../common/components/examination_parts/TAMA1/練習_英語GAB/検査１';
// ↑
const LogHeader = 'Exam.vue';

export default {
  // PageTitle: 'Webテスト',
  mixins: [commonMixin],
  components: {
    HeaderComponent_TG,
    HeaderComponent_TAMA,
    FooterComponent,
    ExamIntermediateComponent_TG,
    ExamIntermediateComponent_TAMA,
    ExamFinishComponent_TG,
    ExamFinishComponent_TAMA,
    tg1_a_t1, tg1_a_t2,
    tg1_b_t1, tg1_b_t2,
    tg1_pa_kei1_t1, tg1_pa_kei2_t1, tg1_pa_kei3_t1, tg1_pa_kei4_t1, tg1_pa_gen_t1, tg1_pb_gk_t1,
    tama1_a_t1, tama1_a_t2,
    tama1_b_t1, tama1_b_t2,
    tama1_c_t1, tama1_c_t2,
    tama1_p_hy_t1, tama1_p_ha_t1, tama1_p_sg_t1, tama1_p_eg_t1,
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      schoolExamination: null,
      examination: null,
      student: null,
      currentExamPartIdx: null,
      currentPageNo: null,
      examPartRemainingSec: null,
      examPartTimerId: null,
      examPartCutOffTime: null,
      lastReportedData: null,
      showIntermediateComponent: false, // showIntermediateComponent と showFinishComponent は排他指定（通常は両方false）
      showFinishComponent: false, // showIntermediateComponent と showFinishComponent は排他指定（通常は両方false）
      isTimedOut: false,
      isDebug: false, // デバッグ表示用（本番false）
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true/*, 'データを取得しています...'*/);
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        // 全ての検査が終了している場合はhomeにリダイレクト
        console.log(`examPartSet.started_at/finished_at=[${this.examPartSet.started_at}][${this.examPartSet.finished_at}]`);
        // 【「全ての検査が終了」の判定方法】
        // ・本テスト（復習を含む） → examPartSet.finished_atがセット済み
        // ・練習版 → 同上 ※但し、次回の「開始」と見分けが付かない
        if (!this.examPartSet.is_practice_test) { // （復習を含む）本テスト？
          if (this.examPartSet.finished_at) {
            console.log('redirecting exam to home');
            this.$router.push({ name: 'home' });
            return;
          }
        } else { // 練習版？
          // チェックしない（できない）
        }

        // 現在の検査（のインデックス）の確定
        console.log(`examPartSet.id_current_sc_ex_student_ex_prt_set_exam_part=[${this.examPartSet.id_current_sc_ex_student_ex_prt_set_exam_part}] examPartSet.current_exam_part_idx=[${this.examPartSet.current_exam_part_idx}]`);
        // 「２回目以降の練習の開始」時のみ、current_exam_part_idx（と、その導出元となるid_current_sc_ex_student_ex_prt_exam_part）にはnullがセットされているので補正（先頭の検査をセット）が必要
        // （↑id_current_sc_ex_student_ex_prt_exam_partは、開始通知によってサーバー側でも正しい値がセットされる）
        // 上記以外（「（復習を含む）本テストの開始」「１回目の練習の開始」「（全ての）再開」）はサーバーから送られた値をそのまま使用可能
        // 【「２回目以降の練習の開始（＝前回の練習が完了済み）」の判定方法】
        // examPartSet.started_at、examPartSet.finished_atが共にセット済み、かつcurrent_exam_part_idx（とid_current_sc_ex_student_ex_prt_exam_part）がNULL
        if (this.examPartSet.is_practice_test) { // 練習版？
          if (this.examPartSet.started_at && this.examPartSet.finished_at && !this.examPartSet.current_exam_part_idx) {
            this.examPartSet.id_current_sc_ex_student_ex_prt_set_exam_part = this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[0].id;
            this.examPartSet.current_exam_part_idx = 0;
            console.log(`MODIFIED examPartSet.id_current_sc_ex_student_ex_prt_set_exam_part=[${this.examPartSet.id_current_sc_ex_student_ex_prt_set_exam_part}] examPartSet.current_exam_part_idx=[${this.examPartSet.current_exam_part_idx}]`);
          }
        }
        this.currentExamPartIdx = this.examPartSet.current_exam_part_idx;
        console.log(`this.currentExamPartIdx=[${this.currentExamPartIdx}]`);

        // 試験開始または再開
        console.log(`this.currentMyExaminationPart.started_at/finished_at=[${this.currentMyExaminationPart.started_at}][${this.currentMyExaminationPart.finished_at}]`);
        // 【「開始」の判定方法】
        // ・本テスト（復習を含む） → currentMyExaminationPart.started_atがNULL（finished_atもNULL）
        // ・練習版（１回目） → 同上
        // ・練習版（２回目以降） → currentMyExaminationPart.started_atもfinished_atも（直近回の値が）セット済み
        // 【「再開」の判定方法】
        // ・本テスト（復習を含む） → currentMyExaminationPart.started_atがセット済み、finished_atはNULL
        // ・練習版（１回目） → 同上
        // ・練習版（２回目以降） → 同上
        if ( ! (this.currentMyExaminationPart.started_at && !this.currentMyExaminationPart.finished_at) ) { // 検査開始？（＝「上記の再開」でない？）

          // サーバーへ検査の開始を通知
          this.setSavingStatus(true);
          this.putStudentExamPartToStart()
          .then((response) => {
            console.log(`[${LogHeader}] created() putStudentExamPartToStart()-then START`, response);
            let scExStudentExPrtSetExamPart = response.data.sc_ex_student_ex_prt_set_exam_part;
            window.token = sessionStorage.token = response.data.access_token; // トークンリフレッシュ
            this.setSavingStatus(false);

            // 各ページは各answerが（単一文字列解答であろうとも）arrayであることを前提としているのに、
            // 新規開始の時に（answer_setの中身が構築されていなくてarrayになっていなくて）エラーになる（＝画面が表示されない）対策
            this.reflectExamPartAnswerSet(scExStudentExPrtSetExamPart.answer_set, this.currentMasterExaminationPart); // 再開時と同じ答案一式復元処理

            this.$nextTick(() => { // この時点では（onMovePage内で参照する）$refsの準備ができていないので$nextTickを使う

              this.onMovePage(scExStudentExPrtSetExamPart.current_page_no/*=1*/, true); // これで初めて画面が表示される

              this.startExamPartTimer(this.currentMasterExaminationPart.time_limit_sec); // 検査単位タイマー開始

            }); // $nextTick()

            console.log(`[${LogHeader}] created() putStudentExamPartToStart()-then END`);
          }).catch((error) => {
            console.error(`[${LogHeader}] created() putStudentExamPartToStart()-catch START`, error);
            this.setSavingStatus(false);
            this.setResult(error.response);
            console.error(`[${LogHeader}] created() putStudentExamPartToStart()-catch END`, error);
          });
          // ↑開始通知

        } else { // 検査再開？

          // サーバーへ検査の再開を通知
          this.setSavingStatus(true);
          this.putStudentExamPartToRestart()
          .then((response) => {
            console.log(`[${LogHeader}] created() putStudentExamPartToRestart-then START`, response);
            let scExStudentExPrtSetExamPart = response.data.sc_ex_student_ex_prt_set_exam_part;
            window.token = sessionStorage.token = response.data.access_token; // トークンリフレッシュ
            this.setSavingStatus(false);

            this.reflectExamPartAnswerSet(scExStudentExPrtSetExamPart.answer_set, this.currentMasterExaminationPart); // 現在の検査の答案一式を復元

            this.$nextTick(() => { // この時点では（onMovePage内で参照する）$refsの準備ができていないので$nextTickを使う

              this.onMovePage(scExStudentExPrtSetExamPart.current_page_no/* ページ位置の復元 */, true); // これで初めて画面が表示される

              this.startExamPartTimer(scExStudentExPrtSetExamPart.exam_part_remaining_sec/*残り時間*/); // 検査単位タイマー開始

            }); // $nextTick()

            console.log(`[${LogHeader}] created() putStudentExamPartToRestart-then END`);
          }).catch((error) => {
            console.error(`[${LogHeader}] created() putStudentExamPartToRestart-catch START`, error);
            this.setSavingStatus(false);
            this.setResult(error.response);
            console.error(`[${LogHeader}] created() putStudentExamPartToRestart-catch END`, error);
          });
          // ↑再開通知

        } // if-else

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      }); // ↑getSpecial(url)

  }, // created()
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  beforeRouteLeave(to, from, next) {
    console.log(`[${LogHeader}] beforeRouteLeave() CALLED`);
    this.stopExamPartTimer(); // 検査単位タイマー停止
    next();
  },
  computed: {
    headerComponent() {
      if (this.examination) {
        switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
          case 'TG1':   return 'HeaderComponent_TG';
          case 'TAMA1': return 'HeaderComponent_TAMA';
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    intermediateComponent() {
      if (this.examination) {
        switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
          case 'TG1':   return 'ExamIntermediateComponent_TG';
          case 'TAMA1': return 'ExamIntermediateComponent_TAMA';
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    finishComponent() {
      if (this.examination) {
        switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
          case 'TG1':   return 'ExamFinishComponent_TG';
          case 'TAMA1': return 'ExamFinishComponent_TAMA';
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    examPartSet() { return this.student&&this.student.organized_exam_part_sets? this.student.organized_exam_part_sets[this.$route.params.examPartSetId]: null; },
    currentMasterExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.master_exam_parts[this.currentExamPartIdx]: null; },
    currentMyExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[this.currentExamPartIdx]: null; },
    examPartMainComponent() { return this.currentMasterExaminationPart? this.currentMasterExaminationPart.main_component_name: null; },
    currentPageName() { return (this.currentMasterExaminationPart&&this.currentPageNo)? this.currentMasterExaminationPart.page_names[this.currentPageNo-1]: null; },
    currentPageParams() { return this.currentMasterExaminationPart&&this.currentPageName? this.currentMasterExaminationPart.all_page_params_set[this.currentPageName]: null; },
    isLastPage() { return this.currentMasterExaminationPart? (this.currentPageNo == this.currentMasterExaminationPart.page_count ): false; },
    isLastExamPart() { return this.examPartSet? (this.currentExamPartIdx == this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc.length - 1 - 1 /* 「総合」も含む配列なので（length-1から）さらに -1 する */ ): false; },
  },
  methods: {
    startExamPartTimer(examPartRemainingSec) {
      // console.log(`[${LogHeader}] startExamPartTimer(${examPartRemainingSec}) START`);
      this.stopExamPartTimer(); // 念の為
      this.isTimedOut = false;
      this.examPartRemainingSec = examPartRemainingSec;
      this.examPartCutOffTime = (new Date()).getTime() + examPartRemainingSec * 1000;
      this.examPartTimerId = setInterval(() => {
        this.examPartRemainingSec = Math.ceil((this.examPartCutOffTime - (new Date()).getTime()) / 1000);
        // console.log(`[${LogHeader}] examPartRemainingSec=[${this.examPartRemainingSec}]`);
        if (this.examPartRemainingSec <= 0) {
          // console.log(`[${LogHeader}] TIMED OUT !!`);
          // clearInterval忘れ防止の為にタイマーを止める
          // this.stopExamPartTimer(); // 負値になってもサーバーへの通知は止めたくないので廃止 → 停止はbeforeRouteLeaveに任せる
        }
      }, 1000);
      console.log(`[${LogHeader}] startExamPartTimer(${examPartRemainingSec}) started timer (id=[${this.examPartTimerId}])`);
      // console.log(`[${LogHeader}] startExamPartTimer(${examPartRemainingSec}) END`);
    },
    stopExamPartTimer() {
      // console.log(`[${LogHeader}] stopExamPartTimer() START`);
      if (this.examPartTimerId) {
        clearInterval(this.examPartTimerId);
        console.log(`[${LogHeader}] stopExamPartTimer() stopped timer (id=[${this.examPartTimerId}])`);
        this.examPartTimerId = null;
        // 停止後の参照にも対応できるよう、 examPartRemainingSec, examPartCutOffTime は保持
      }
      // console.log(`[${LogHeader}] stopExamPartTimer() END`);
    },
    reflectExamPartAnswerSet(answerSet, examinationPart) {
      // （サーバーに保存されていた）答案一式をall_page_params_setに埋め込む ※admin/views/master/examination_parts/Edit.vueのafterCreatedGetThen()と似たロジック
      // このロジックはサーバー側で持つことが望ましいが、extractExamPartAnswerSet()との対比のためにクライアント側に置いている
      console.log(`[${LogHeader}] reflectExamPartAnswerSet() START`);
      // examinationPart.all_page_params_set内の各問題のanswerに復元
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        let pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            let questionParams = pageParams[questionKey];
            questionParams.answer = answerSet[questionKey] ?? [];
            // console.log(`[${pageKey}][${questionKey}]=[${questionParams.answer}]`); // デバッグ用（本番コメントアウト）
          }
        }
      }
      console.log(`[${LogHeader}] reflectExamPartAnswerSet() END`, examinationPart.all_page_params_set);
    },
    extractExamPartAnswerSet(examinationPart) {
      // 現在のall_page_params_setから回答・答案を抽出して答案一式を作成する ※admin/views/master/examination_parts/Edit.vueのmakeSaveParams()と似たロジック
      // このロジックをサーバー側で持つことも検討したが、一時保存のサーバー負荷を考慮するとクライアントで処理する方が望ましい
      console.log(`[${LogHeader}] extractExamPartAnswerSet() START`);
      let answerSet = {};
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        const pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            const questionParams = pageParams[questionKey];
            const answer = questionParams.answer ?? [];
            // answer.sort(); // 入力順に格納されているのでソート → checkboxだけでなく、順序に意味のある複数文字列（例：分子、分母）回答もあるので厳禁（checkboxの場合は下位でソートしておく）
            // console.log(`[${pageKey}][${questionKey}]=[${answer}]`); // デバッグ用（本番コメントアウト）

            // 全回答一律の補正（カンマ除去、半角英数字化）
            // XXX 回答の補正を必要最小限にとどめるにはquestionParamsにフラグ等の情報が必要（あるいは各ページ(.vue)で補正する）
            const adjAnswer = answer.map((value/*, index, array*/) => {
              const adjusted = this.wideAlphaNumToHalf(this.removeComma(this.trimWhiteSpace(value))); // 220115 trimWhiteSpace追加
              if (adjusted !== value) {
                console.log(`adjusted [${value}]→[${adjusted}]`);
              }
              return adjusted;
            }, this);

            answerSet[questionKey] = adjAnswer;
            console.log(`[${pageKey}][${questionKey}]=[${answerSet[questionKey]}]`);
          } // if
        } // for(questionKey)
      } // for(pageKey)
      console.log(`[${LogHeader}] extractExamPartAnswerSet() END`, answerSet);
      return answerSet;
    }, // extractExamPartAnswerSet()
    putStudentExamPartToStart() { // currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToStart() CALLED`);
      const url = `sc_ex_student_ex_prt_set_exam_part/start`;
      return this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(), // or toLocaleString() サーバー側でserver_client_initial_time_diff_secの算出に使うのでtoISOString()の方がよいと思う
      });
    },
    putStudentExamPartToRestart() { // currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToRestart() CALLED`);
      const url = `sc_ex_student_ex_prt_set_exam_part/restart`;
      return this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(), // or toLocaleString() サーバー側でserver_client_initial_time_diff_secの算出に使うのでtoISOString()の方がよいと思う
      });
    },
    processPutStudentExamPartWithTmpData(skipIfSame) { // 他と違ってthen,catchまでひとまとめにしてある
      console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData(${skipIfSame}) CALLED`);

      const answerSet = this.extractExamPartAnswerSet(this.currentMasterExaminationPart);

      // （サーバー負荷を考慮して）前回と同じデータ（かつ前回送信からclient_report_interval_sec*10秒未満）なら送信しない
      if (skipIfSame) {
        const reportingData = {
          current_page_no: this.currentPageNo,
          answer_set_str: JSON.stringify(answerSet),
          last_reported_at_msec: Date.now(),
        };
        if (this.lastReportedData &&
          reportingData.current_page_no == this.lastReportedData.current_page_no &&
          reportingData.answer_set_str == this.lastReportedData.answer_set_str &&
          reportingData.last_reported_at_msec - this.lastReportedData.last_reported_at_msec < this.currentMasterExaminationPart.client_report_interval_sec * 1000) {
          console.log(`SKIP Reporting (page(now/prev)=[${reportingData?.current_page_no??''}][${this.lastReportedData?.current_page_no??''}] sec(now/prev)=[${reportingData.last_reported_at_msec/1000}][${this.lastReportedData.last_reported_at_msec/1000}])`);
          return;
        }
        console.log(`NOT SKIP Reporting (page(now/prev)=[${reportingData?.current_page_no??''}][${this.lastReportedData?.current_page_no??''}] sec(now/prev)[${reportingData.last_reported_at_msec/1000}][${this.lastReportedData?this.lastReportedData.last_reported_at_msec/1000:''}])`);
        this.lastReportedData = reportingData;
      }

      const url = `sc_ex_student_ex_prt_set_exam_part/tmp`;
      this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(), // or toLocaleString() サーバー側で時間計算に使う可能性があるのでtoISOString()の方がよいと思う
        exam_part_remaining_sec: this.examPartRemainingSec,
        current_page_no: this.currentPageNo,
        answer_set: answerSet,
      })
      .then((response) => {
        console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-then START`, response);
        console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-catch END`, error);
      });
    },
    putStudentExamPartToFinish() { // currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToFinish() CALLED`);
      const url = `sc_ex_student_ex_prt_set_exam_part/finish`;
      return this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(), // or toLocaleString() サーバー側で時間計算に使う可能性があるのでtoISOString()の方がよいと思う
        exam_part_remaining_sec: this.examPartRemainingSec,
        current_page_no: this.currentPageNo,
        answer_set: this.extractExamPartAnswerSet(this.currentMasterExaminationPart),
      });
    },
    onPopState() {
      console.log(`[${LogHeader}] onPopState() CALLED`);
      window.history.pushState(null, null, null);
      alert('ブラウザの「戻る」は使用できません。');
    },
    onBeforeUnload(event) {
      console.log(`[${LogHeader}] onBeforeUnload() CALLED`);
      // ブラウザにタブクローズの確認ダイアログを出してもらう see https://developer.mozilla.org/ja/docs/Web/API/Window/beforeunload_event
      event.preventDefault();
      event.returnValue = '';
    },
    onInput(e) {
      // ↓デバッグ用（本番コメントアウト）
      // console.log(`[${LogHeader}] onInput() CALLED`, e);
      // ↑デバッグ用（本番コメントアウト）
      // ↓デバッグ用（本番コメントアウト）
      // this.extractExamPartAnswerSet(this.currentMasterExaminationPart); // extractExamPartAnswerSet()内のログもコメント解除しないといけない
      // ↑デバッグ用（本番コメントアウト）
    },
    canMovePage() {
      console.log(`[${LogHeader}] canMovePage() CALLED`);
      if (typeof this.$refs.refExamPartComponent.canMovePage === 'function') {
        return this.$refs.refExamPartComponent.canMovePage();
      }
      return true;
    },
    onMovePage(newPageNo, force =false) {
      console.log(`[${LogHeader}] onMovePage(newPageNo=[${newPageNo}],force=[${force}]) CALLED`);
      if (!force && !this.canMovePage()) {
        return;
      }
      this.resetError();
      this.resetStatus();
      if (newPageNo >= 1 && newPageNo <= this.currentMasterExaminationPart.page_count) {
        this.currentPageNo = newPageNo; // ページ変更
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      }
    },
    onMoveToNextExamPart1() {
      console.log(`[${LogHeader}] onMoveToNextExamPart1() CALLED`);
      if (!this.canMovePage()) {
        console.log(`[${LogHeader}] onMoveToNextExamPart1() END-1`);
        return;
      }

      // キャンセルされることもあるので検査単位タイマーはまだ止めない

      this.showFinishComponent = true; // 終了確認画面を表示
    },
    onMoveToNextExamPart2() {
      console.log(`[${LogHeader}] onMoveToNextExamPart2() CALLED`);

      this.stopExamPartTimer(); // 検査単位タイマー停止
      this.resetError();
      this.resetStatus();

      // サーバーへ現在の検査の終了を通知
      this.setSavingStatus(true);
      this.putStudentExamPartToFinish()
      .then((response) => {
        console.log(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToFinish()-then START`, response);
        this.setSavingStatus(false);

        this.currentPageNo = null; // （ちらつき防止の為に）いったん問題画面を消す

        this.showFinishComponent = false; // 終了確認画面を消す

        this.showIntermediateComponent = true; // 次の検査前の案内画面を表示

        console.log(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToFinish()-then END`, response);
      }).catch((error) => {
        console.error(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToFinish()-catch START`, error);
        this.setSavingStatus(false);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToFinish()-catch END`, error);
      });
      // ↑終了通知

      console.log(`[${LogHeader}] onMoveToNextExamPart2() END`);
    }, // onMoveToNextExamPart2()
    onMoveToNextExamPart3() { // onMoveToNextExamPart2()の続きの処理
      console.log(`[${LogHeader}] onMoveToNextExamPart3() CALLED`);

      this.showIntermediateComponent = false; // 次の検査前の案内画面を消す

      this.currentExamPartIdx++; // 次の検査

      // サーバーへ次の検査の開始を通知
      this.setSavingStatus(true);
      this.putStudentExamPartToStart()
      .then((response) => {
        console.log(`[${LogHeader}] onMoveToNextExamPart3() putStudentExamPartToStart()-then START`, response);
        let scExStudentExPrtSetExamPart = response.data.sc_ex_student_ex_prt_set_exam_part;
        window.token = sessionStorage.token = response.data.access_token; // トークンリフレッシュ
        this.setSavingStatus(false);

        // 各ページは各answerが（単一文字列解答であろうとも）arrayであることを前提としているのに、
        // 新規開始の時に（answer_setの中身が構築されていなくて）エラーになる（＝画面が表示されない）対策
        this.reflectExamPartAnswerSet(scExStudentExPrtSetExamPart.answer_set, this.currentMasterExaminationPart);

        this.onMovePage(scExStudentExPrtSetExamPart.current_page_no/*=1*/, true); // これで画面が再表示される

        this.startExamPartTimer(this.currentMasterExaminationPart.time_limit_sec); // 検査単位タイマー開始

        console.log(`[${LogHeader}] onMoveToNextExamPart3() putStudentExamPartToStart()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] onMoveToNextExamPart3() putStudentExamPartToStart()-catch START`, error);
        this.setSavingStatus(false);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onMoveToNextExamPart3() putStudentExamPartToStart()-catch END`, error);
      });
      // ↑開始通知

      console.log(`[${LogHeader}] onMoveToNextExamPart3() END`);
    }, // onMoveToNextExamPart3()
    onFinishAll1() {
      console.log(`[${LogHeader}] onFinishAll1() CALLED`);
      if (!this.canMovePage()) {
        console.log(`[${LogHeader}] onFinishAll1() END-1`);
        return;
      }

      // キャンセルされることもあるので検査単位タイマーはまだ止めない

      this.showFinishComponent = true; // 終了確認画面を表示
    },
    onFinishAll2() { // onFinishAll1()の続きの処理
      console.log(`[${LogHeader}] onFinishAll2() CALLED`);

      this.stopExamPartTimer(); // 検査単位タイマー停止
      this.resetError();
      this.resetStatus();

      // サーバーへ現在の検査（＝最終の検査）の終了を通知
      this.setSavingStatus(true);
      this.putStudentExamPartToFinish()
      .then((response) => {
        console.log(`[${LogHeader}] onFinishAll2() putStudentExamPartToFinish()-then START`, response);
        this.setSavingStatus(false);

        this.currentPageNo = null; // （あまり意味はないが）問題画面を消す

        this.showFinishComponent = false; // （あまり意味はないが）終了確認画面を消す

        // 結果画面へ遷移
        this.$router.push({ name: 'result', params: { examPartSetId: this.$route.params.examPartSetId, showDescAtFirst: false } });

        console.log(`[${LogHeader}] onFinishAll2() putStudentExamPartToFinish()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] onFinishAll2() putStudentExamPartToFinish()-catch START`, error);
        this.setSavingStatus(false);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onFinishAll2() putStudentExamPartToFinish()-catch END`, error);
      });
      // ↑終了通知

      console.log(`[${LogHeader}] onFinishAll2() END`);
    }, // onFinishAll2()
  }, // method
  watch: {
    examPartRemainingSec: {
      handler(newValue, oldValue) {
        // console.log(`[${LogHeader}] watch:examPartRemainingSec([${oldValue}]→[${newValue}]) CALLED`); // デバッグ用（本番コメントアウト）
        if (oldValue != null && newValue != null) {

          if (newValue <= 0) { // ０秒になってもタイマーを止めていないので何度も呼ばれることに注意

            // 制限時間管理（タイムアウト処理）
            if (!this.isTimedOut) { // タイムアウト後初めての呼び出し？

              this.isTimedOut = true;

              this.showFinishComponent = true; // 終了確認画面を表示 ※「isTimedOut=true」と組み合わせることで、終了ボタン押下しかできなくなる
              // ↑ canMovePageチェックをバイパスして onMoveToNextExamPart1() や onFinishAll1() を実行するのと同意
              // ↑ 終了ボタン押下後、 onFinishAll2() と onMoveToNextExamPart2() に分岐する

            }

          }

          // 定期的に（client_report_interval_sec間隔）サーバーへ現在の答案一式やページ番号を通知 ※０秒を切っても通知し続ける
          if (this.currentMasterExaminationPart.client_report_interval_sec > 0 &&
                      newValue % this.currentMasterExaminationPart.client_report_interval_sec == 0) {
            console.log(`[${LogHeader}] watch:examPartRemainingSec([${oldValue}]→[${newValue}]) attempting to report`);
            this.processPutStudentExamPartWithTmpData(true/*ページと回答に変更がなければスキップ*/);
          }

        } // if (newValue!=null)
      } // handler()
    }, // examPartRemainingSec
  }, // watch
}
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"
.outer-card
  width: 60rem
.card-body
  +mq(max_575)
    padding: 1.0rem 0.5rem
#examination_parts_wrapper
  width: 100%
</style>
