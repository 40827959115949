<template>
  <div>
    <div v-if="isDebug" class="debug_str">TG/ExamIntermediateComponent isTimedOut={{ isTimedOut }}</div>

    <div class="row mt-5">
      <div class="col-12 px-2 px-sm-5 my_h6">
        言語は終了です。<br/>
        続けて計数の試験が始まります。
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 px-2 px-sm-5 my_h6">
        準備ができたら「開始する」ボタンを押してください。<br/>
        開始ボタンを押さない場合は、<span class="text-red">{{ timeoutSec }}秒後に自動的に試験が開始されます。</span>
      </div>
    </div>

    <div class="row mt-5 mb-5">
      <div class="col-12 px-2 px-sm-5 text-right">
        <button class="btn btn-lg btn-red mx-4 my-1 px-5 py-2 large_button_font" @click.prevent="onStartNext()">開始する</button>
      </div>
    </div>

  </div>
</template>

<script>
const LogHeader = 'TG/ExamIntermediateComponent';
export default {
  props: {
    scExStudentExamPartSet: { default: null },
    isTimedOut: { default: false },
    isDebug: { default: false },
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      timeoutSec: 10,
      cutOffTime: null,
      remainingSec: null,
      timerId: null,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
    this.startTimer(); // mounted か activated のどちらがよいか要検討 ※activated()が発生しなかった！！
  },
  activated() {
    console.log(`[${LogHeader}] activated() CALLED`);
    // this.startTimer(); // mounted か activated のどちらがよいか要検討 ※activated()が発生しなかった！！
  },
  computed: {
  },
  methods: {
    startTimer() {
      console.log(`[${LogHeader}] startTimer() START`);
      this.stopTimer(); // 念の為
      this.remainingSec = this.timeoutSec;
      this.cutOffTime = (new Date()).getTime() + this.remainingSec * 1000;
      this.timerId = setInterval(() => {
        this.remainingSec = Math.ceil((this.cutOffTime - (new Date()).getTime()) / 1000);
        console.log(`[${LogHeader}] remainingSec=[${this.remainingSec}]`);
        if (this.remainingSec <= 0) {
          this.stopTimer(); // イベントを重複発行しない為にタイマー停止
          console.log(`[${LogHeader}] TIMED OUT !! -> emitting(start-next)`);
          this.$emit('start-next');
        }
      }, 1000);
      console.log(`[${LogHeader}] startTimer() started timer (id=[${this.timerId}])`);
      // console.log(`[${LogHeader}] startTimer() END`);
    },
    stopTimer() {
      console.log(`[${LogHeader}] stopTimer() CALLED`);
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
      }
    },
    onStartNext() {
      console.log(`[${LogHeader}] onStartNext() -> emitting(start-next)`);
      this.$emit('start-next');
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../common/sass/_base"
</style>
