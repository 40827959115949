<template>

  <div class="row" :class="{ 'RD_result_header_footer_wrapper': [ ContentTypes.result ].includes(contentType) }">
    <div v-if="isDebug" class="col-12 debug_str">ResultHeaderFooterComponent contentType={{ contentType }}</div>

    <div v-if="[ ContentTypes.result ].includes(contentType)" class="col-12 RD_result_header_footer_inner_wrapper">
      <div>
        <button                           class="btn btn-sm btn-light_gray2 RD_go_back_home_button" @click.prevent="onGotoHome()">トップページに戻る</button>
        <a v-if="canShowPdfButton"        class="btn btn-sm btn-orange2     RD_save_as_pdf_button" :href="pdfHref" target="_blank">PDF保存</a>
        <button                           class="btn btn-sm btn-light_blue2 RD_goto_desc_button" @click.prevent="onShowDesc1()">解説</button>
      </div>
      <div>
        <button v-if="showLogoutButton"   class="btn btn-sm btn-light_gray2 RD_logout_button" @click.prevent="onLogout()">ログアウト</button>
      </div>
    </div>

    <div v-else-if="[ ContentTypes.desc1, ContentTypes.desc2 ].includes(contentType)" class="col-12 d-md-flex">
      <button                           class="btn btn-sm btn-light_gray2 mx-2            my-2 px-3" @click.prevent="onGotoHome()">トップページに戻る</button>
      <button                           class="btn btn-sm btn-light_blue2 mx-2            my-2 px-3" @click.prevent="onShowResult()">試験結果に戻る</button>
      <button v-if="canShowDesc1Button" class="btn btn-sm btn-green       mx-2            my-2 px-3" @click.prevent="onShowDesc1()">{{ scExStudentExamPartSet.t2_is_english ? '英語' : '言語' }}の解説</button>
      <button v-if="canShowDesc2Button" class="btn btn-sm btn-green       mx-2            my-2 px-3" @click.prevent="onShowDesc2()">計数の解説</button>
      <button v-if="showLogoutButton"   class="btn btn-sm btn-light_gray2 mx-2 ml-md-auto my-2 px-4" @click.prevent="onLogout()">ログアウト</button>
    </div>

  </div><!-- row -->

</template>

<script>
const LogHeader = 'ResultHeaderFooterComponent';
const ContentTypes = { // Result本体と整合注意 ※冗長だがこの２ヶ所だけのはずなのでとりあえず放置
  result: '個人結果表',
  desc1: '検査１ 解説',
  desc2: '検査２ 解説',
};

export default {
  props: {
    contentType: { default: '' }, // one of ContentTypes
    scExStudentExamPartSet: { default: null },
    token: { default: '' },
    showLogoutButton: { default: false },
    isDebug: { default: false },
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      ContentTypes,
    };
  },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    canShowDesc1Button() { return this.contentType != ContentTypes.desc1; },
    desc2Exists() { return this.scExStudentExamPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[1] && !this.scExStudentExamPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[1].is_dummy_exam_part; },
    canShowDesc2Button() { return this.desc2Exists && this.contentType != ContentTypes.desc2; },
    canShowPdfButton() { return this.scExStudentExamPartSet.is_real_test || this.scExStudentExamPartSet.is_review_test; },
    pdfHref() { return `/export/personal_report?token=${this.token}&exam_part_set_id=${this.scExStudentExamPartSet.exam_part_set_id}`; },
  },
  methods: {
    onGotoHome() {
      console.log(`[${LogHeader}] onGotoHome() -> emitting(goto-home)`);
      this.$emit('goto-home');
    },
    onLogout() {
      console.log(`[${LogHeader}] onLogout() -> emitting(logout)`);
      this.$emit('logout');
    },
    onShowResult() {
      console.log(`[${LogHeader}] onShowResult() -> emitting(show-result)`);
      this.$emit('show-result');
    },
    onShowDesc1() {
      console.log(`[${LogHeader}] onShowDesc1() -> emitting(show-desc1)`);
      this.$emit('show-desc1');
    },
    onShowDesc2() {
      console.log(`[${LogHeader}] onShowDesc2() -> emitting(show-desc2)`);
      this.$emit('show-desc2');
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"

// ↓ 2024-02 Re-Designed by 「TAMA1 結果画面 Bootstrap 4 Grid Template - All 5 Sizes.xd」

.row.RD_result_header_footer_wrapper
  font-family: $fontFamilyMeiryo

  .col-12.RD_result_header_footer_inner_wrapper
    display: flex
    justify-content: space-between

    // Y方向の余白は他者に任せる
    +marginY(0)
    +paddingY(0)

    // X方向の余白はXD上の値から -5px する（card-bodyのpadding(=20px)とrowのmargin(=-15px)を考慮）
    $adjX: -5px // -(20-15)
    +paddingX(13.5px + $adjX, 13.0px + $adjX)
    +mq_min_sm
      +paddingX(10.0px + $adjX, 16.4px + $adjX)
    +mq_min_md
      +paddingX(19.0px + $adjX, 34.1px + $adjX)
    +mq_min_lg
      +paddingX(19.0px + $adjX, 51.8px + $adjX)
    +mq_min_xl
      +paddingX(90.0px + $adjX, 187.8px + $adjX)

    // トップページボタン
    .RD_go_back_home_button
      +widthAndHeight(65.78px, 16.92px)
      +fontSizeAndLineHeight(6px, 1.4)
      +paddingX(0)
      +mq_min_sm
        +widthAndHeight(82.61px, 22.05px)
        +fontSizeAndLineHeight(7px)
      +mq_min_md
        +widthAndHeight(105.0px, 28.02px)
        +fontSizeAndLineHeight(9px)
      +mq_min_lg
        +widthAndHeight(146.06px, 34.34px)
        +fontSizeAndLineHeight(12px)
      +mq_min_xl
        +widthAndHeight(185.0px, 43.5px)
        +fontSizeAndLineHeight(16px)

    // PDF保存ボタン
    .RD_save_as_pdf_button
      +widthAndHeight(71.94px, 17.5px)
      +fontSizeAndLineHeight(7px, 1.2)
      margin-left: 12.8px
      +mq_min_sm
        +widthAndHeight(93.76px, 22.81px)
        +fontSizeAndLineHeight(10px, 1.4)
        margin-left: 23.7px
      +mq_min_md
        +widthAndHeight(119.17px, 28.99px)
        +fontSizeAndLineHeight(12px, 1.8)
        margin-left: 30.1px
      +mq_min_lg
        +widthAndHeight(146.06px, 35.53px)
        +fontSizeAndLineHeight(15px, 1.8)
        margin-left: 19.5px
      +mq_min_xl
        +widthAndHeight(185.0px, 45.0px)
        +fontSizeAndLineHeight(18px, 2.0)
        margin-left: 24.7px

    // 解説ボタン
    .RD_goto_desc_button
      +widthAndHeight(71.94px, 17.5px)
      +fontSizeAndLineHeight(7px, 1.2)
      margin-left: 13.2px
      +mq_min_sm
        +widthAndHeight(93.76px, 22.81px)
        +fontSizeAndLineHeight(10px)
        margin-left: 13.3px
      +mq_min_md
        +widthAndHeight(119.17px, 28.99px)
        +fontSizeAndLineHeight(12px)
        margin-left: 17.0px
      +mq_min_lg
        +widthAndHeight(146.06px, 35.53px)
        +fontSizeAndLineHeight(15px)
        margin-left: 20.8px
      +mq_min_xl
        +widthAndHeight(185.0px, 45.0px)
        +fontSizeAndLineHeight(18px)
        margin-left: 26.3px

    // ログアウトボタン
    .RD_logout_button
      +widthAndHeight(81.0px, 18.0px)
      +fontSizeAndLineHeight(7px, 1.2)
      +mq_min_sm
        +widthAndHeight(102.08px, 23.27px)
        +fontSizeAndLineHeight(8px)
      +mq_min_md
        +widthAndHeight(127.86px, 29.15px)
        +fontSizeAndLineHeight(10px)
      +mq_min_lg
        +widthAndHeight(157.35px, 35.72px)
        +fontSizeAndLineHeight(14px)
      +mq_min_xl
        +widthAndHeight(185.0px, 42.0px)
        +fontSizeAndLineHeight(16px)

  // .RD_result_header_footer_inner_wrapper

// .RD_result_header_footer_wrapper

// ↑ 2024-02 Re-Designed

</style>
