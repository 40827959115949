<template>
  <div class="card-header text-white bg-nav_header">
    <div class="header_title">WEBテスト {{ caption }}</div>
  </div>
</template>

<script>
const LogHeader = 'HeaderComponent';
export default {
  props: {
    caption: { default: '' },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"

.header_title
  font-size: 0.9rem
  +mq(min_451)
    font-size: 1.0rem
  +mq(min_576)
    font-size: 1.25rem

</style>
