<template>
    <div class="RD_outer_card_wrapper">
      <div class="outer-card">
        <div class="card border-secondary">

          <!-- card-header -->
          <component v-if="examination&&student"
                      :is="headerComponent" calledBy="Home"
                      :examination="examination" :student="student"
          />

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <!-- row * N  -->
            <component :is="mainComponent" v-if="schoolExamination" :schoolExamination="schoolExamination" :examination="examination" :examPartSets="examPartSets" :student="student" />

            <!-- row  -->
            <FooterComponent calledBy="Home" />

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- outer-card -->
    </div><!-- RD_outer_card_wrapper -->
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import HeaderComponent_TG from '../components/TG/HeaderComponent';
import HeaderComponent_TAMA from '../components/TAMA/HeaderComponent';
import FooterComponent from '../components/FooterComponent';
import HomeComponent_TG from '../components/TG/HomeComponent';
import HomeComponent_TAMA from '../components/TAMA/HomeComponent';
const LogHeader = 'Home.vue';

export default {
  // PageTitle: 'Webテスト',
  mixins: [commonMixin],
  components: {
    HeaderComponent_TG,
    HeaderComponent_TAMA,
    FooterComponent,
    HomeComponent_TG,
    HomeComponent_TAMA,
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      schoolExamination: null,
      examination: null,
      examPartSets: null,
      student: null,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.examPartSets = response.data.exam_part_sets;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        if (!this.student.registered_at) { // プロフィール未登録ならプロフィール画面へリダイレクト
          console.log('redirecting home to profile');
          this.$router.push({ name: 'profile'});
        }
        else if (!this.student.checked_precautions_at) { // 注意事項未確認なら注意事項画面へリダイレクト
          console.log('redirecting home to precautions');
          this.$router.push({ name: 'precautions'});
        }

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    headerComponent() {
      if (this.examination) {
        switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
          case 'TG1':   return 'HeaderComponent_TG';
          case 'TAMA1': return 'HeaderComponent_TAMA';
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    mainComponent() {
      if (this.examination) {
        switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
          case 'TG1':   return 'HomeComponent_TG';
          case 'TAMA1': return 'HomeComponent_TAMA';
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
  },
  methods: {
    onPopState() {
      console.log(`[${LogHeader}] onPopState() CALLED`);
      window.history.pushState(null, null, null);
      alert('ブラウザの「戻る」は使用できません。');
    },
  }, // methods
}
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"

</style>
