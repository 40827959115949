<template>
  <div>
    <div v-if="isDebug" class="debug_str">TG/ExamFinishComponent isTimedOut={{ isTimedOut }}</div>

    <div class="row mt-5">
      <div class="col-12 px-2 px-sm-5 my_h6">
        すべてを送信して試験を終了しますか？
      </div>
    </div>

    <div class="row mt-5 mb-5">
      <div class="col-12 text-center">
        <button class="btn btn-lg btn-light_blue1 mx-4 my-1 px-5 py-2 large_button_font" :disabled="isTimedOut" @click.prevent="onCancel()">元のページに戻る</button>
        <button class="btn btn-lg btn-orange3     mx-4 my-1 px-5 py-2 large_button_font"                        @click.prevent="onFinish()">すべてを送信して終了する</button>
      </div>
    </div>

  </div>
</template>

<script>
const LogHeader = 'TG/ExamFinishComponent';
export default {
  props: {
    scExStudentExamPartSet: { default: null },
    isTimedOut: { default: false },
    isDebug: { default: false },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  computed: {
  },
  methods: {
    onCancel() {
      console.log(`[${LogHeader}] onCancel() -> emitting(cancel)`);
      this.$emit('cancel');
    },
    onFinish() {
      console.log(`[${LogHeader}] onFinish() -> emitting(finish)`);
      this.$emit('finish');
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../common/sass/_base"
</style>
