<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 col-md-10 offset-md-1 col-lg-auto mx-lg-auto outer-card">
        <div class="card border-secondary">

          <component v-if="examination&&student"
                      :is="headerComponent" calledBy="Profile"
                      :examination="examination" :student="student"
          /><!-- includes card-header -->

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-right">
                <button class="btn btn-sm btn-light_gray mx-2 mb-1 px-3" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
              </div>
            </div>

            <div class="row" v-if="student&&school">
              <form class="col-12 col-sm-10 offset-sm-1">

                <div class="form-group">
                  <label for="selectSchoolFaculty">所属１</label>
                  <select class="form-control" id="selectSchoolFaculty" :class="{ 'is-invalid': hasError('id_school_faculty') }" :disabled="!isAvailable" v-model="student.id_school_faculty" @change="student.id_school_department=null">
                    <option :value="null">選択して下さい</option>
                    <option v-for="faculty in school.faculties" :key="`faculty_${faculty.id}`" :value="faculty.id">{{ faculty.faculty_name }}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="selectSchoolDepartment">所属２</label>
                  <select class="form-control" id="selectSchoolDepartment" :class="{ 'is-invalid': hasError('id_school_department') }" :disabled="!isAvailable||needNotSelectDepartment" v-model="student.id_school_department">
                    <option :value="null">{{ needNotSelectDepartment? '選択不要です': '選択して下さい' }}</option>
                    <option v-for="department in filteredSchoolDepartments" :key="`department_${department.id}`" :value="department.id">{{ department.department_name }}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label for="inputStudentNo">学籍番号（出席番号）</label>
                  <input type="text" id="inputStudentNo" class="form-control" :class="{ 'is-invalid': hasError('student_no') }" :disabled="!isAvailable" v-model="student.student_no">
                </div>

                <div class="form-group">
                  <label for="inputLastName">名前（姓）</label>
                  <input type="text" id="inputLastName" class="form-control" :class="{ 'is-invalid': hasError('last_name') }" :disabled="!isAvailable" v-model="student.last_name">
                </div>

                <div class="form-group">
                  <label for="inputFirstName">名前（名）</label>
                  <input type="text" id="inputFirstName" class="form-control" :class="{ 'is-invalid': hasError('first_name') }" :disabled="!isAvailable" v-model="student.first_name">
                </div>

                <div class="form-group">
                  <label for="inputKanaLastName">フリガナ（姓）</label>
                  <input type="text" id="inputKanaLastName" class="form-control" :class="{ 'is-invalid': hasError('kana_last_name') }" :disabled="!isAvailable" v-model="student.kana_last_name">
                </div>

                <div class="form-group">
                  <label for="inputKanaFirstName">フリガナ（名）</label>
                  <input type="text" id="inputKanaFirstName" class="form-control" :class="{ 'is-invalid': hasError('kana_first_name') }" :disabled="!isAvailable" v-model="student.kana_first_name">
                </div>

                <div class="form-group">
                  <label for="inputMailAddress">メールアドレス</label>
                  <input type="email" id="inputMailAddress" class="form-control" :class="{ 'is-invalid': hasError('mail_address') }" :disabled="!isAvailable" v-model="student.mail_address">
                </div>

                <div class="form-group text-center">
                  <button class="btn btn-secondary mx-2 my-2" :disabled="!isAvailable" @click.prevent="onCancel">戻る</button>
                  <button class="btn btn-primary mx-2 my-2" :disabled="!isAvailable" @click.prevent="onSave">更新する</button>
                </div>

              </form>
            </div><!-- row -->

            <FooterComponent />

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import HeaderComponent_TG from '../components/TG/HeaderComponent';
import HeaderComponent_TAMA from '../components/TAMA/HeaderComponent';
import FooterComponent from '../components/FooterComponent.vue';
const LogHeader = 'Profile.vue';

export default {
  PageTitle: '登録情報編集',
  mixins: [commonMixin],
  components: {
    HeaderComponent_TG,
    HeaderComponent_TAMA,
    FooterComponent,
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      school: null,
      schoolExamination: null,
      examination: null,
      student: null,
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url1 = `school`;
    this.resourceApi
      .getSpecial(url1)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url1})-then START`, response);
        this.school = response.data.school;
        const url2 = `school_examination`;
        this.resourceApi
          .getSpecial(url2)
          .then((response) => {
            console.log(`[${LogHeader}] created() getSpecial(${url2})-then START`, response);
            this.schoolExamination = response.data.school_examination;
            this.examination = response.data.examination;
            this.student = response.data.student;
            this.setLoadingStatus(false);
            console.log(`[${LogHeader}] created() getSpecial(${url2})-then END`);
          }).catch((error) => {
            console.error(`[${LogHeader}] created() getSpecial(${url2})-catch START`, error);
            this.setResult(error.response);
            console.error(`[${LogHeader}] created() getSpecial(${url2})-catch END`, error);
          });
        console.log(`[${LogHeader}] created() getSpecial(${url1})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url1})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url1})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    headerComponent() {
      if (this.examination) {
        switch (this.examination.exam_name) { // TODO 試験種別にコンポーネントを変える必要あり
          case 'TG1':   return 'HeaderComponent_TG';
          case 'TAMA1': return 'HeaderComponent_TAMA';
        }
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    filteredSchoolDepartments() {
      if (this.school) {
        for (let faculty of this.school.faculties) {
          if (faculty.id == this.student.id_school_faculty) {
            return faculty.departments;
          }
        }
      }
      return [];
    },
    needNotSelectDepartment() { return this.student.id_school_faculty != null && this.filteredSchoolDepartments.length == 0; },
  },
  methods: {
    onSave(stayAfterSaved) {
      console.log(`[${LogHeader}] onSave() START`);
      scrollTo(0, 0);
      this.resetError();
      this.resetStatus();
      this.setSavingStatus(true, '登録情報を保存しています...');
      let params = {
        id: this.student.id,
        id_school_faculty: this.student.id_school_faculty,
        id_school_department: this.student.id_school_department,
        student_no: this.student.student_no,
        last_name: this.student.last_name,
        first_name: this.student.first_name,
        kana_last_name: this.student.kana_last_name,
        kana_first_name: this.student.kana_first_name,
        mail_address: this.student.mail_address,
      };
      const url = `school_exam_student/profile`;
        this.resourceApi
        .putSpecial(url, params)
        .then((response) => {
          console.log(`[${LogHeader}] onSave() putSpecial(${url})-then START`, response);
          this.student = response.data.school_exam_student;
          this.setStatusMessage(`登録情報を更新しました。`);
          this.setTimeoutToGoBack(); // to home
          console.log(`[${LogHeader}] onSave() putSpecial(${url})-then END`);
        }).catch((error) => {
          console.error(`[${LogHeader}] onSave() putSpecial(${url})-catch START`, error.response);
          this.setResult(error.response);
          this.setErrorMessage(`登録情報を更新できませんでした。`);
          this.setSavingStatus(false);
          console.error(`[${LogHeader}] onSave() putSpecial(${url})-catch END`);
        });
      console.log(`[${LogHeader}] onSave() END`);
    }, // onSave()
  }, // methods
  watch: {
    'student.student_no': function(newValue, oldValue) {
      console.log(`[${LogHeader}] watch:student_no([${oldValue}]→[${newValue}]) CALLED`);
      this.student.student_no = this.trimWhiteSpace(this.wideAlphaNumToHalf(newValue)); // できればdebounce化したい
    },
    'student.last_name': function(newValue, oldValue) {
      console.log(`[${LogHeader}] watch:last_name([${oldValue}]→[${newValue}]) CALLED`);
      this.student.last_name = this.trimWhiteSpace(newValue); // できればdebounce化したい
    },
    'student.first_name': function(newValue, oldValue) {
      console.log(`[${LogHeader}] watch:first_name([${oldValue}]→[${newValue}]) CALLED`);
      this.student.first_name = this.trimWhiteSpace(newValue); // できればdebounce化したい
    },
    'student.kana_last_name': function(newValue, oldValue) {
      console.log(`[${LogHeader}] watch:kana_last_name([${oldValue}]→[${newValue}]) CALLED`);
      this.student.kana_last_name = this.trimWhiteSpace(this.halfKanaToWide(newValue)); // できればdebounce化したい
    },
    'student.kana_first_name': function(newValue, oldValue) {
      console.log(`[${LogHeader}] watch:kana_first_name([${oldValue}]→[${newValue}]) CALLED`);
      this.student.kana_first_name = this.trimWhiteSpace(this.halfKanaToWide(newValue)); // できればdebounce化したい
    },
  }, // watch
};
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"
.outer-card
  width: 60rem
</style>
