<template>
  <div class="mb-3">

    <!-- <Header/> -->

    <main>

      <div v-if="['home','result'].includes($route.name)" class="container-fluid px-0 pt-3">
        <div class="RD_inner_container">
          <router-view/>
        </div>
      </div>

      <div v-else class="container-fluid">
        <div class="row mt-1">
          <div class="col-lg-12 offset-lg-0">
            <router-view/>
          </div>
        </div>
      </div>

    </main>

    <!-- <Footer/> -->

  </div>
</template>

<script>
// studentのヘッダー・フッターは、（データを引き渡すことがあるので）Appではなく各画面でimportしている
// import Header from './components/HeaderComponent.vue';
// import Footer from './components/FooterComponent.vue';
const LogHeader =  'App.vue';

export default {
  components: {
    // Header,
    // Footer,
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    console.log(this.$route);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    // httpStatus() { return this.$store.state.error.code; },
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../common/sass/_base"

.RD_inner_container
  margin-left: auto
  margin-right: auto
  width: 100%
  max-width: 430px
  +mq_min_sm
    max-width: 576px
  +mq_min_md
    max-width: 768px
  +mq_min_lg
    max-width: 1024px
  +mq_min_xl
    max-width: 1440px

</style>
