<template>
  <div class="RD_whole_wrapper">

    <div class="row RD_inner_header_wrapper">
      <div class="col-12 RD_inner_header">
        <router-link v-if="student" class="btn btn-sm btn-light_gray RD_edit_profile_button" :to="{ name: 'profile' }">&nbsp;登録情報の確認・訂正&nbsp;</router-link>
        <button class="btn btn-sm btn-light_gray RD_logout_button" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
      </div>
    </div>

    <!-- ↓本テスト -->
    <div class="row mt-3 RD_exam_group_1_wrapper">
      <div class="col-12 RD_exam_group_1">

        <div class="row bg-light RD_exam_group_title_wrapper">
          <div class="col-12 RD_exam_group_title">本テスト</div>
        </div>

        <div class="row mt-2">
          <ul class="col-12 RD_exam_group_desc">
            <li>A〜Cの３タイプのテストをそれぞれ受験してください。検査開始後は、最後まで受験してください。</li>
            <li>A〜Cは言語＋計数あるいは英語＋計数の問題で構成されています。</li>
            <li>初回受験時の最終問題終了後に「解説を⾒る」ボタンおよび「試験結果」ボタンが表⽰されますので、復習の際にご活⽤ください。</li>
            <li>計数の問題は、すべて電卓の使用が可能です。</li>
          </ul>
        </div>

        <div class="row mt-2">
          <div class="col-6 col-sm-4 text-center RD_exam_sub_group_wrapper" v-for="examPartSetId in [ 'TAMA_Ａタイプ_本試験', 'TAMA_Ｂタイプ_本試験', 'TAMA_Ｃタイプ_本試験' ]" :key="`exam_${examPartSetId}`">

            <div class="row">
              <div class="col-12">
                <router-link v-if="isSuspended(examPartSetId)" tag="button" class="btn btn-lg btn-red RD_exam_restart_button" :to="routingToExam(examPartSetId)" :disabled="schoolExamination.is_disabled">試験を再開する</router-link>
                <button v-else class="btn btn-lg btn-blue RD_exam_start_button" data-toggle="modal" :data-target="getStartModalId(examPartSetId)" :disabled="!canStart本試験(examPartSetId)">{{ get本試験Caption(examPartSetId) }}</button>
              </div>
            </div>

            <div class="row">
              <div class="col-10 offset-1 col-md-6 offset-md-0 RD_exam_result_button_wrapper">
                <router-link tag="button" :to="routingToResult(examPartSetId, false)" class="btn btn-sm btn-green RD_exam_result_button" :disabled="!hasResult(examPartSetId)">試験結果</router-link>
              </div>
              <div class="col-10 offset-1 col-md-6 offset-md-0 RD_exam_desc_button_wrapper">
                <router-link tag="button" :to="routingToResult(examPartSetId, true)" class="btn btn-sm btn-light_blue2 RD_exam_desc_button" :disabled="!hasResult(examPartSetId)">解説を見る</router-link>
              </div>
            </div>

          </div><!-- col * N -->
        </div>

      </div>
    </div>
    <!-- ↑本テスト -->

    <!-- ↓本テストの復習利用 -->
    <div class="row mt-4 RD_exam_group_2_wrapper">
      <div class="col-12 RD_exam_group_2">

        <div class="row bg-light RD_exam_group_title_wrapper">
          <div class="col-12 RD_exam_group_title">本テストの復習利用</div>
        </div>

        <div class="row mt-2">
          <ul class="col-12 RD_exam_group_desc">
            <li class="d-md-none">初回受験の後、復習利⽤として4回受験できます。</li>
            <li class="d-md-none">復習利⽤の受験ボタンは初回受験後に表⽰されます。</li>
            <li class="d-none d-md-block">初回受験の後、復習利⽤として4回受験できます。復習利⽤の受験ボタンは初回受験後に表⽰されます。</li>
          </ul>
        </div>

        <div class="row mt-1">

          <div class="col-12 col-sm-4 text-center RD_exam_sub_group_wrapper">
            <div class="row">
              <div class="col-12 RD_exam_sub_group_caption">復習利用　Ａタイプ</div>
            </div>
            <div class="row RD_exam_sub_sub_group_wrapper" v-for="(examPartSetId, idx) in [ 'TAMA_Ａタイプ_復習１', 'TAMA_Ａタイプ_復習２', 'TAMA_Ａタイプ_復習３', 'TAMA_Ａタイプ_復習４' ]" :key="`review_a_${examPartSetId}`">
              <div class="col-12" >
                <div class="d-inline RD_exam_sub_sub_group_number" >{{ String.fromCharCode('①'.charCodeAt(0) + idx) }}</div>
                <router-link v-if="isSuspended(examPartSetId)" tag="button" :to="routingToExam(examPartSetId)" class="btn btn-sm btn-orange1 RD_exam_restart_button">再開する</router-link>
                <button v-else class="btn btn-sm btn-orange1 RD_exam_start_button" data-toggle="modal" :data-target="getStartModalId(examPartSetId)" :disabled="!canStart復習テスト(examPartSetId)">受験する</button>
                <router-link tag="button" :to="routingToResult(examPartSetId, false)" class="btn btn-sm btn-green RD_exam_result_button" :disabled="!hasResult(examPartSetId)">{{ get復習結果Caption(idx) }}</router-link>
              </div>
            </div><!-- row * N -->
          </div><!-- col -->

          <div class="col-12 col-sm-4 text-center RD_exam_sub_group_wrapper">
            <div class="row">
              <div class="col-12 RD_exam_sub_group_caption">復習利用　Ｂタイプ</div>
            </div>
            <div class="row RD_exam_sub_sub_group_wrapper" v-for="(examPartSetId, idx) in [ 'TAMA_Ｂタイプ_復習１', 'TAMA_Ｂタイプ_復習２', 'TAMA_Ｂタイプ_復習３', 'TAMA_Ｂタイプ_復習４' ]" :key="`review_a_${examPartSetId}`">
              <div class="col-12" >
                <div class="d-inline RD_exam_sub_sub_group_number" >{{ String.fromCharCode('①'.charCodeAt(0) + idx) }}</div>
                <router-link v-if="isSuspended(examPartSetId)" tag="button" :to="routingToExam(examPartSetId)" class="btn btn-sm btn-orange1 RD_exam_restart_button">再開する</router-link>
                <button v-else class="btn btn-sm btn-orange1 RD_exam_start_button" data-toggle="modal" :data-target="getStartModalId(examPartSetId)" :disabled="!canStart復習テスト(examPartSetId)">受験する</button>
                <router-link tag="button" :to="routingToResult(examPartSetId, false)" class="btn btn-sm btn-green RD_exam_result_button" :disabled="!hasResult(examPartSetId)">{{ get復習結果Caption(idx) }}</router-link>
              </div>
            </div><!-- row * N -->
          </div><!-- col -->

          <div class="col-12 col-sm-4 text-center RD_exam_sub_group_wrapper">
            <div class="row">
              <div class="col-12 RD_exam_sub_group_caption">復習利用　Ｃタイプ</div>
            </div>
            <div class="row RD_exam_sub_sub_group_wrapper" v-for="(examPartSetId, idx) in [ 'TAMA_Ｃタイプ_復習１', 'TAMA_Ｃタイプ_復習２', 'TAMA_Ｃタイプ_復習３', 'TAMA_Ｃタイプ_復習４' ]" :key="`review_a_${examPartSetId}`">
              <div class="col-12" >
                <div class="d-inline RD_exam_sub_sub_group_number" >{{ String.fromCharCode('①'.charCodeAt(0) + idx) }}</div>
                <router-link v-if="isSuspended(examPartSetId)" tag="button" :to="routingToExam(examPartSetId)" class="btn btn-sm btn-orange1 RD_exam_restart_button">再開する</router-link>
                <button v-else class="btn btn-sm btn-orange1 RD_exam_start_button" data-toggle="modal" :data-target="getStartModalId(examPartSetId)" :disabled="!canStart復習テスト(examPartSetId)">受験する</button>
                <router-link tag="button" :to="routingToResult(examPartSetId, false)" class="btn btn-sm btn-green RD_exam_result_button" :disabled="!hasResult(examPartSetId)">{{ get復習結果Caption(idx) }}</router-link>
              </div>
            </div><!-- row * N -->
          </div><!-- col -->

        </div><!-- row -->

      </div>
    </div>
    <!-- ↑本テストの復習利用 -->

    <!-- ↓練習版テスト -->
    <div class="row mt-4 RD_exam_group_3_wrapper">
      <div class="col-12 RD_exam_group_3">

        <div class="row bg-light RD_exam_group_title_wrapper">
          <div class="col-12 RD_exam_group_title">練習版テスト</div>
        </div>

        <div class="row mt-2">
          <ul class="col-12 RD_exam_group_desc">
            <li>本テストの受験前・受験後のいずれも練習版テストを受験できます。</li>
            <li>練習版テストは練習回数に制限なく、何度でも受験できます。</li>
          </ul>
        </div>

        <div class="row mt-1">
          <div class="col-12">

            <div class="row RD_exam_sub_group_wrapper" v-for="(examPartSetId, idx) in [ 'TAMA_練習_表の読み取り', 'TAMA_練習_表の穴埋め', 'TAMA_練習_四則逆算', 'TAMA_練習_英語GAB' ]" :key="`practice_${examPartSetId}`">
              <div class="col-12 col-md-4 col-xl-3 RD_exam_sub_group_caption">{{ get練習テストCaption(examPartSetId) }}</div>
              <div class="col-12 col-md-8 col-xl-9 RD_button_wrapper">
                <router-link v-if="isSuspended(examPartSetId)" tag="button" :to="routingToExam(examPartSetId)" class="btn btn-sm btn-orange1 RD_exam_restart_button" :disabled="!canStart練習テスト(examPartSetId)">再開する</router-link>
                <button v-else class="btn btn-sm btn-orange1 RD_exam_start_button" data-toggle="modal" :data-target="getStartModalId(examPartSetId)" :disabled="!canStart練習テスト(examPartSetId)">受験<span class="d-none d-sm-inline">する</span></button>
                <router-link tag="button" :to="routingToResult(examPartSetId, true)" class="btn btn-sm btn-light_blue2 RD_exam_desc_button" :disabled="!hasResult(examPartSetId)">解説<span class="d-none d-sm-inline">を見る</span></router-link>
                <router-link tag="button" :to="routingToResult(examPartSetId, false)" class="btn btn-sm btn-green RD_exam_result_button" :disabled="!hasResult(examPartSetId)"><span class="d-none d-sm-inline">最新の試験</span>結果</router-link>
              </div>
            </div><!-- row * N -->

          </div><!-- col -->
        </div><!-- row -->

      </div>
    </div>
    <!-- ↑練習版テスト -->

    <!-- ↓Ａ～Ｃタイプ用受験確認モーダル -->
    <div class="modal fade" v-for="examPartSetId in [
        'TAMA_Ａタイプ_本試験', 'TAMA_Ａタイプ_復習１', 'TAMA_Ａタイプ_復習２', 'TAMA_Ａタイプ_復習３', 'TAMA_Ａタイプ_復習４',
        'TAMA_Ｂタイプ_本試験', 'TAMA_Ｂタイプ_復習１', 'TAMA_Ｂタイプ_復習２', 'TAMA_Ｂタイプ_復習３', 'TAMA_Ｂタイプ_復習４',
        'TAMA_Ｃタイプ_本試験', 'TAMA_Ｃタイプ_復習１', 'TAMA_Ｃタイプ_復習２', 'TAMA_Ｃタイプ_復習３', 'TAMA_Ｃタイプ_復習４',
      ]"
      :key="`modalStart_${examPartSetId}`" :id="`modalStart_${examPartSetId}`" tabindex="-1" role="dialog"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content bg-light">
          <div class="modal-body py-5">

            <div class="row">
              <div class="col-12 px-2 px-sm-5 my_h6">
                この試験は2つの科目で構成されています。<br/>
                ①{{ getStartModalParams(examPartSetId).caption_1 }}（問題数{{ getStartModalParams(examPartSetId).question_count_1 }}問・制限時間<span class="text-red">{{ getStartModalParams(examPartSetId).time_limit_min_1 }}</span>分）<br/>
                ②{{ getStartModalParams(examPartSetId).caption_2 }}（問題数{{ getStartModalParams(examPartSetId).question_count_2 }}問・制限時間<span class="text-red">{{ getStartModalParams(examPartSetId).time_limit_min_2 }}</span>分）<br/>
                <br/>
                {{ getStartModalParams(examPartSetId).caption_1 }}が終了すると⾃動的に{{ getStartModalParams(examPartSetId).caption_2 }}も開始されます。試験を中断することはできません。<br/>
                <span class="text-red">必ず最後まで受験してください。</span><br/>
                <br/>
                <br/>
                試験を開始する場合は、<span class="text-red">開始ボタン</span>をクリックしてください。<br/>
                <br/>
                〜試験開始の前に〜<br/>
                ■{{ getStartModalParams(examPartSetId).caption_1 }}は、課題⽂について設問があり、正しいと思うものを３つの選択肢から選ぶ問題です。<br/>
                ■{{ getStartModalParams(examPartSetId).caption_2 }}は、各設問で図表が与えられますので、その図表に関する設問に答える問題です。<br/>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button class="btn btn-lg btn-light_blue1 mx-4 my-1 px-sm-4 py-2 large_button_font" data-dismiss="modal">トップページに戻る</button>
                <router-link tag="button" :to="routingToExam(examPartSetId)" class="btn btn-lg btn-red mx-4 my-1 px-5 py-2 large_button_font" data-dismiss="modal">開始</router-link>
              </div>
            </div>

          </div><!-- modal-body -->
        </div>
      </div>
    </div>
    <!-- ↑Ａ～Ｃタイプ用受験確認モーダル -->

    <!-- ↓練習版用受験確認モーダル -->
    <div class="modal fade" v-for="examPartSetId in [
        'TAMA_練習_表の読み取り', 'TAMA_練習_表の穴埋め', 'TAMA_練習_四則逆算', 'TAMA_練習_英語GAB',
      ]"
      :key="`modalStart_${examPartSetId}`" :id="`modalStart_${examPartSetId}`" tabindex="-1" role="dialog"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content bg-light">
          <div class="modal-body py-5">

            <div class="row mt-4">
              <div class="col-12 px-2 px-sm-5 my_h6">
                試験を開始する場合は、<span class="text-red">開始ボタン</span>をクリックしてください。
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <button class="btn btn-lg btn-light_blue1 mx-4 my-1 px-sm-4 py-2 large_button_font" data-dismiss="modal">トップページに戻る</button>
                <router-link tag="button" :to="routingToExam(examPartSetId)" class="btn btn-lg btn-red mx-4 my-1 px-5 py-2 large_button_font" data-dismiss="modal">開始</router-link>
              </div>
            </div>

          </div><!-- modal-body -->
        </div>
      </div>
    </div>
    <!-- ↑本試験以外用受験確認モーダル -->

  </div><!-- RD_whole_wrapper -->
</template>

<script>
import commonMixin from '../../mixins/common';
const LogHeader = 'TAMA/HomeComponent.vue';

export default {
  mixins: [commonMixin],
  props: {
    schoolExamination: { default: null },
    examination: { default: null },
    examPartSets: { default: null },
    student: { default: null },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    ogExPrtSets() { return this.student.organized_exam_part_sets; },
  },
  methods: {
    isSuspended(examPartSetId) {
      return !!this.ogExPrtSets[examPartSetId].started_at && !this.ogExPrtSets[examPartSetId].finished_at;
    },
    canStart本試験(examPartSetId) {
      if (examPartSetId.indexOf('復習') >= 0) {
        return false; // ここに来るのは想定外
      }
      return !this.schoolExamination.is_disabled && !this.ogExPrtSets[examPartSetId].finished_at;
    },
    canStart復習テスト(examPartSetId) {
      if (examPartSetId.indexOf('復習') < 0) {
        return false; // ここに来るのは想定外
      }

      // （試験が無効化されているかどうかに関係なく）本テストが完了していれば復習テストは利用可とする

      if (this.ogExPrtSets[examPartSetId].finished_at) { // この復習テストが終了済み？
        return false;
      }

      switch (examPartSetId) { // ひとつ前の試験が終了済み？
        case 'TAMA_Ａタイプ_復習１':  return !!this.ogExPrtSets['TAMA_Ａタイプ_本試験'].finished_at;
        case 'TAMA_Ａタイプ_復習２':  return !!this.ogExPrtSets['TAMA_Ａタイプ_復習１'].finished_at;
        case 'TAMA_Ａタイプ_復習３':  return !!this.ogExPrtSets['TAMA_Ａタイプ_復習２'].finished_at;
        case 'TAMA_Ａタイプ_復習４':  return !!this.ogExPrtSets['TAMA_Ａタイプ_復習３'].finished_at;

        case 'TAMA_Ｂタイプ_復習１':  return !!this.ogExPrtSets['TAMA_Ｂタイプ_本試験'].finished_at;
        case 'TAMA_Ｂタイプ_復習２':  return !!this.ogExPrtSets['TAMA_Ｂタイプ_復習１'].finished_at;
        case 'TAMA_Ｂタイプ_復習３':  return !!this.ogExPrtSets['TAMA_Ｂタイプ_復習２'].finished_at;
        case 'TAMA_Ｂタイプ_復習４':  return !!this.ogExPrtSets['TAMA_Ｂタイプ_復習３'].finished_at;

        case 'TAMA_Ｃタイプ_復習１':  return !!this.ogExPrtSets['TAMA_Ｃタイプ_本試験'].finished_at;
        case 'TAMA_Ｃタイプ_復習２':  return !!this.ogExPrtSets['TAMA_Ｃタイプ_復習１'].finished_at;
        case 'TAMA_Ｃタイプ_復習３':  return !!this.ogExPrtSets['TAMA_Ｃタイプ_復習２'].finished_at;
        case 'TAMA_Ｃタイプ_復習４':  return !!this.ogExPrtSets['TAMA_Ｃタイプ_復習３'].finished_at;
      }

      return false; // ここに来るのは想定外
    }, // canStart復習テスト()
    canStart練習テスト(examPartSetId) {
      if (examPartSetId.indexOf('復習') >= 0) {
        return false; // ここに来るのは想定外
      }

      /*
        試験無効化済み かつ 本テスト未完了 の場合は練習版はdisabled(=falseを返す)とする
        ＝無効化されていても本テスト受験済みなら利用できる
        ＝本テスト未完了でも無効化されていなければ利用できる
       */
      return !this.schoolExamination.is_disabled || !!this.ogExPrtSets[examPartSetId].finished_at;
    },
    get本試験Caption(examPartSetId) {
      switch (examPartSetId) {
        case 'TAMA_Ａタイプ_本試験':  return '本試験受験 Ａタイプ';
        case 'TAMA_Ｂタイプ_本試験':  return '本試験受験 Ｂタイプ';
        case 'TAMA_Ｃタイプ_本試験':  return '本試験受験 Ｃタイプ';
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    get復習結果Caption(idx) {
      return `復習${ String.fromCharCode('①'.charCodeAt(0) + idx) } 結果`;
    },
    get練習テストCaption(examPartSetId) {
      switch (examPartSetId) {
        case 'TAMA_練習_表の読み取り':  return '表の読み取り（15分）';
        case 'TAMA_練習_表の穴埋め':    return '表の穴埋め（20分）';
        case 'TAMA_練習_四則逆算':      return '四則逆算（9分）';
        case 'TAMA_練習_英語GAB':       return '英語GAB（10分）';
      }
      return 'ERROR'; // ここに来るのは想定外
    },
    getStartModalId(examPartSetId, withHash = true) {
      return  `${(withHash ? '#' : '')}modalStart_${examPartSetId}`;
    },
    routingToExam(examPartSetId) {
      return { name: 'exam', params: { examPartSetId: examPartSetId } };
    },
    hasResult(examPartSetId) {
      return !!this.ogExPrtSets[examPartSetId].has_personal_report_set;
    },
    routingToResult(examPartSetId, showDescAtFirst) {
      return { name: 'result', params: { examPartSetId: examPartSetId, showDescAtFirst: showDescAtFirst } };
    },
    getStartModalParams(examPartSetId) {
      switch (examPartSetId) {
        case 'TAMA_Ａタイプ_本試験':
        case 'TAMA_Ａタイプ_復習１':
        case 'TAMA_Ａタイプ_復習２':
        case 'TAMA_Ａタイプ_復習３':
        case 'TAMA_Ａタイプ_復習４':
          return {
            caption_1: '言語',
            question_count_1: 36,
            time_limit_min_1: 15,
            caption_2: '計数',
            question_count_2: 29,
            time_limit_min_2: 15,
          };
        case 'TAMA_Ｂタイプ_本試験':
        case 'TAMA_Ｂタイプ_復習１':
        case 'TAMA_Ｂタイプ_復習２':
        case 'TAMA_Ｂタイプ_復習３':
        case 'TAMA_Ｂタイプ_復習４':
          return {
            caption_1: '言語',
            question_count_1: 32,
            time_limit_min_1: 10,
            caption_2: '計数',
            question_count_2: 50,
            time_limit_min_2: 9,
          };
        case 'TAMA_Ｃタイプ_本試験':
        case 'TAMA_Ｃタイプ_復習１':
        case 'TAMA_Ｃタイプ_復習２':
        case 'TAMA_Ｃタイプ_復習３':
        case 'TAMA_Ｃタイプ_復習４':
          return {
            caption_1: '英語',
            question_count_1: 24,
            time_limit_min_1: 10,
            caption_2: '計数',
            question_count_2: 20,
            time_limit_min_2: 20,
          };
      } // switch (examPartSetId)

      return null; // ここに来るのは想定外
    }, // getStartModalParams()
  }, // methods
}
</script>

<style lang="sass" scoped>
@import "../../../common/sass/_base"

// ↓ 2024-01 Re-Designed by 「TAMA1 top画面　Bootstrap 4 Grid 231230 .xd」

.RD_whole_wrapper

  // ↓ 全体の左右余白設定 ※微調整は各クラスで行う
  $cardBodyMargin: 20px // 1.25rem
  $wholePaddingX_xs: 0px
  $wholePaddingX_sm: 10px
  $wholePaddingX_md: 20px
  $wholePaddingX_lg: 30px
  $wholePaddingX_xl: 150px
  +paddingX($wholePaddingX_xs)
  +mq_min_sm
    +paddingX($wholePaddingX_sm)
  +mq_min_md
    +paddingX($wholePaddingX_md)
  +mq_min_lg
    +paddingX($wholePaddingX_lg)
  +mq_min_xl
    +paddingX($wholePaddingX_xl)
  // ↑

  // 各groupタイトル用のmixin
  =groupTitleWrapperMarginAndPadding($baseMargin, $xsMargin, $smMargin, $mdMargin, $lgMargin, $xlMargin)
    +marginX( - ( $baseMargin + $xsMargin ) )
    +paddingX($xsMargin)
    +mq_min_sm
      +marginX( - ( $baseMargin + $smMargin ) )
      +paddingX($smMargin)
    +mq_min_md
      +marginX( - ( $baseMargin + $mdMargin ) )
      +paddingX($mdMargin)
    +mq_min_lg
      +marginX( - ( $baseMargin + $lgMargin ) )
      +paddingX($lgMargin)
    +mq_min_xl
      +marginX( - ( $baseMargin + $xlMargin ) )
      +paddingX($xlMargin)

  .RD_inner_header
    font-family: $fontFamilyMeiryo
    text-align: right

    // 登録情報編集ボタン
    .RD_edit_profile_button
      +widthAndHeight(163px, 33px)
      margin-right: 53px // XD(xs=430px)は117px指定。414pxなら109or92px、375pxなら70or53pxだと崩れない。
      +fontSizeAndLineHeight(12px, 2.0)
      +mq_min_sm
        +widthAndHeight(165px, 33px)
        margin-right: 90px
        // +fontSizeAndLineHeight(12px, 2.0)
      +mq_min_md
        +widthAndHeight(190px, 40px)
        margin-right: 47px
        +fontSizeAndLineHeight(14px, 2.3)
      +mq_min_lg
        +widthAndHeight(206px, 42px)
        margin-right: 20px
        +fontSizeAndLineHeight(16px, 2.0)
      +mq_min_xl
        +widthAndHeight(190px, 42px)
        margin-right: 47px
        +fontSizeAndLineHeight(14px, 2.3)

    // ログアウトボタン
    .RD_logout_button
      +widthAndHeight(100px, 33px)
      +fontSizeAndLineHeight(13px)
      +mq_min_sm
        +widthAndHeight(90px, 33px)
        // +fontSizeAndLineHeight(13px)
      +mq_min_md
        +widthAndHeight(108px, 40px)
        +fontSizeAndLineHeight(14px)
      +mq_min_lg
        +widthAndHeight(131px, 42px)
        +fontSizeAndLineHeight(16px)
      +mq_min_xl
        +widthAndHeight(108px, 42px)
        +fontSizeAndLineHeight(14px)

  // .RD_inner_header

  // ↓ 本テスト
  .RD_exam_group_1
    font-family: $fontFamilyMeiryo
    font-weight: normal

    .RD_exam_group_title_wrapper
      +groupTitleWrapperMarginAndPadding($cardBodyMargin, $wholePaddingX_xs, $wholePaddingX_sm, $wholePaddingX_md, $wholePaddingX_lg, $wholePaddingX_xl)

      // groupタイトル
      .RD_exam_group_title
        font-weight: bold
        font-size: 16px
        +mq_min_sm
          // font-size: 16px
        +mq_min_md
          font-size: 18px
        +mq_min_lg
          font-size: 20px
        +mq_min_xl
          // font-size: 20px

    // .RD_exam_group_title_wrapper

    // 説明文
    .RD_exam_group_desc
      +ulStyleAndIndent(0.2em, 0.5em)
      font-size: 9px
      +mq_min_sm
        font-size: 11px
      +mq_min_md
        font-size: 14px
      +mq_min_lg
        font-size: 15px
      +mq_min_xl
        font-size: 17px

    .RD_exam_sub_group_wrapper

      // 開始ボタン、再開ボタン
      $examStartButtonWidth_md: 194px
      $examStartButtonWidth_lg: 210px
      $examStartButtonWidth_xl: 254px
      .RD_exam_start_button, .RD_exam_restart_button
        +widthAndHeight(135px, 33px)
        margin-top: 15px
        margin-bottom: 5.7px
        +fontSizeAndLineHeight(10px)
        +mq_min_sm
          +widthAndHeight(150px, 33px)
          margin-top: unset
          // margin-bottom: 5.7px
          +fontSizeAndLineHeight(12px)
        +mq_min_md
          +widthAndHeight($examStartButtonWidth_md, 36.14px)
          // margin-top: unset
          margin-bottom: 8.4px
          +fontSizeAndLineHeight(13px)
        +mq_min_lg
          +widthAndHeight($examStartButtonWidth_lg, 47.11px)
          // margin-top: unset
          margin-bottom: 17.5px
          +fontSizeAndLineHeight(16px)
        +mq_min_xl
          +widthAndHeight($examStartButtonWidth_xl, 56px)
          // margin-top: unset
          margin-bottom: 21px
          +fontSizeAndLineHeight(19px)

      .RD_exam_result_button_wrapper
        $examResultButtonWidth_md: 90px
        $examResultButtonWidth_lg: 90px
        $examResultButtonWidth_xl: 108px

        // md以上時の結果ボタンの位置調整
        +mq_min_md
          text-align: right
          padding-right: calc( $examStartButtonWidth_md / 2 - $examResultButtonWidth_md )
        +mq_min_lg
          // text-align: right
          padding-right: calc( $examStartButtonWidth_lg / 2 - $examResultButtonWidth_lg )
        +mq_min_xl
          // text-align: right
          padding-right: calc( $examStartButtonWidth_xl / 2 - $examResultButtonWidth_xl )

        // 結果ボタン
        .RD_exam_result_button
          +widthAndHeight(95px, 24px)
          margin-bottom: 5.7px
          +fontSizeAndLineHeight(12px)
          +mq_min_sm
            // +widthAndHeight(95px, 24px)
            // margin-bottom: 5.7px
            // +fontSizeAndLineHeight(12px)
          +mq_min_md
            +widthAndHeight($examResultButtonWidth_md, 30px)
            margin-bottom: unset
            +fontSizeAndLineHeight(13px)
          +mq_min_lg
            +widthAndHeight($examResultButtonWidth_lg, 30px)
            // margin-bottom: unset
            // +fontSizeAndLineHeight(13px)
          +mq_min_xl
            +widthAndHeight($examResultButtonWidth_xl, 42px)
            // margin-bottom: unset
            +fontSizeAndLineHeight(16px)

      // .RD_exam_result_button_wrapper

      .RD_exam_desc_button_wrapper
        $examDescButtonWidth_md: 94.5px
        $examDescButtonWidth_lg: 94.5px
        $examDescButtonWidth_xl: 114px

        // md以上時の解説ボタンの位置調整
        +mq_min_md
          text-align: left
          padding-left: calc( $examStartButtonWidth_md / 2 - $examDescButtonWidth_md )
        +mq_min_lg
          // text-align: left
          padding-left: calc( $examStartButtonWidth_lg / 2 - $examDescButtonWidth_lg )
        +mq_min_xl
          // text-align: left
          padding-left: calc( $examStartButtonWidth_xl / 2 - $examDescButtonWidth_xl )

        // 解説ボタン
        .RD_exam_desc_button
          +widthAndHeight(95px, 24px)
          margin-bottom: 5.7px
          +fontSizeAndLineHeight(12px)
          +mq_min_sm
            // +widthAndHeight(95px, 24px)
            // margin-bottom: 5.7px
            // +fontSizeAndLineHeight(12px)
          +mq_min_md
            +widthAndHeight($examDescButtonWidth_md, 30px)
            margin-bottom: unset
            +fontSizeAndLineHeight(13px)
          +mq_min_lg
            +widthAndHeight($examDescButtonWidth_lg, 30px)
            // margin-bottom: unset
            // +fontSizeAndLineHeight(13px)
          +mq_min_xl
            +widthAndHeight($examDescButtonWidth_xl, 42px)
            // margin-bottom: unset
            +fontSizeAndLineHeight(16px)

      // .RD_exam_desc_button_wrapper

    // .RD_exam_sub_group_wrapper

  // .RD_exam_group_1
  // ↑ 本テスト

  // ↓ 本テストの復習利用
  .RD_exam_group_2
    font-family: $fontFamilyMeiryo
    font-weight: normal

    .RD_exam_group_title_wrapper
      +groupTitleWrapperMarginAndPadding($cardBodyMargin, $wholePaddingX_xs, $wholePaddingX_sm, $wholePaddingX_md, $wholePaddingX_lg, $wholePaddingX_xl)

      // groupタイトル
      .RD_exam_group_title
        font-weight: bold
        font-size: 16px
        +mq_min_sm
          // font-size: 16px
        +mq_min_md
          font-size: 20px
        +mq_min_lg
          // font-size: 20px
        +mq_min_xl
          // font-size: 20px

    // .RD_exam_group_title_wrapper

    // 説明文
    .RD_exam_group_desc
      +ulStyleAndIndent(0.2em, 0.5em)
      font-size: 11px
      +mq_min_sm
        // font-size: 11px
      +mq_min_md
        font-size: 14px
      +mq_min_lg
        font-size: 15px
      +mq_min_xl
        font-size: 18px

    .RD_exam_sub_group_wrapper

      // 見出し
      .RD_exam_sub_group_caption
        padding-right: 210px // センタリングからの位置調整
        font-size: 13px
        +mq_min_sm
          padding-right: 40px
          font-size: 12px
        +mq_min_md
          padding-right: 60px
          font-size: 14px
        +mq_min_lg
          // padding-right: 60px
          font-size: 18px
        +mq_min_xl
          padding-right: 70px
          font-size: 19px

      .RD_exam_sub_sub_group_wrapper
        margin-bottom: 10.2px
        +mq_min_sm
          margin-bottom: 9.7px
        +mq_min_md
          margin-bottom: 8px
        +mq_min_lg
          margin-bottom: 16px
        +mq_min_xl
          margin-bottom: 20px

        // 丸数字
        .RD_exam_sub_sub_group_number
          font-size: 13px
          +mq_min_sm
            font-size: 9px
          +mq_min_md
            font-size: 15px
          +mq_min_lg
            // font-size: 15px
          +mq_min_xl
            font-size: 18px

        // 開始ボタン、再開ボタン
        .RD_exam_start_button, .RD_exam_restart_button
          +widthAndHeight(80px, 30px)
          margin-left: 6px
          +fontSizeAndLineHeight(13px)
          +mq_min_sm
            +widthAndHeight(65.67px, 31px)
            margin-left: 1px
            +fontSizeAndLineHeight(12px)
            +paddingX(0.5px) // 改行対策
          +mq_min_md
            +widthAndHeight(80px, 32px)
            margin-left: 6.2px
            +fontSizeAndLineHeight(13px)
            +paddingX(unset)
          +mq_min_lg
            +widthAndHeight(95px, 30px)
            margin-left: 2px
            // +fontSizeAndLineHeight(13px)
          +mq_min_xl
            +widthAndHeight(108px, 41px)
            margin-left: 6px
            +fontSizeAndLineHeight(15px)

        // 結果ボタン
        .RD_exam_result_button
          +widthAndHeight(120px, 30px)
          margin-left: 76px
          +fontSizeAndLineHeight(12px)
          +mq_min_sm
            +widthAndHeight(70px, 31px)
            margin-left: 4.3px
            +fontSizeAndLineHeight(11px)
            +paddingX(0.5px) // 改行対策
          +mq_min_md
            +widthAndHeight(90px, 32px)
            margin-left: 10px
            +fontSizeAndLineHeight(12px)
            +paddingX(unset)
          +mq_min_lg
            +widthAndHeight(95px, 30px)
            margin-left: 28px
            +fontSizeAndLineHeight(13px)
          +mq_min_xl
            +widthAndHeight(115px, 41px)
            margin-left: 17.8px
            +fontSizeAndLineHeight(15px)

      // .RD_exam_sub_sub_group_wrapper

    // .RD_exam_sub_group_wrapper

  // .RD_exam_group_2
  // ↑ 本テストの復習利用

  // ↓ 練習版テスト
  .RD_exam_group_3
    font-family: $fontFamilyMeiryo
    font-weight: normal

    .RD_exam_group_title_wrapper
      +groupTitleWrapperMarginAndPadding($cardBodyMargin, $wholePaddingX_xs, $wholePaddingX_sm, $wholePaddingX_md, $wholePaddingX_lg, $wholePaddingX_xl)

      // groupタイトル
      .RD_exam_group_title
        font-weight: bold
        font-size: 16px
        +mq_min_sm
          // font-size: 16px
        +mq_min_md
          font-size: 20px
        +mq_min_lg
          // font-size: 20px
        +mq_min_xl
          // font-size: 20px

    // .RD_exam_group_title_wrapper

    // 説明文
    .RD_exam_group_desc
      +ulStyleAndIndent(0.2em, 0.5em)
      font-size: 9px
      +mq_min_sm
        font-size: 11px
      +mq_min_md
        font-size: 14px
      +mq_min_lg
        font-size: 15px
      +mq_min_xl
        font-size: 18px

    .RD_exam_sub_group_wrapper
      margin-bottom: 19.8px
      align-items: center // RD_exam_sub_group_caption を上下中央に配置するため
      +mq_min_sm
        margin-bottom: 16.5px
      +mq_min_md
        margin-bottom: 11px
      +mq_min_lg
        margin-bottom: 21px
      +mq_min_xl
        // margin-bottom: 21px

      // 見出し
      .RD_exam_sub_group_caption
        font-size: 12px
        +mq_min_sm
          // font-size: 12px
        +mq_min_md
          font-size: 14px
          padding-left: 5em
          padding-right: 0
        +mq_min_lg
          font-size: 18px
        +mq_min_xl
          // font-size: 18px
          padding-left: 1em

      .RD_button_wrapper
        +mq_min_md
          padding-left: 0
          padding-right: 0

        // 開始ボタン、再開ボタン
        .RD_exam_start_button, .RD_exam_restart_button
          +widthAndHeight(82px, 30px)
          +fontSizeAndLineHeight(13px)
          +mq_min_sm
            +widthAndHeight(105px, 33px)
            +fontSizeAndLineHeight(12px)
          +mq_min_md
            +widthAndHeight(84.5px, 40px)
            +fontSizeAndLineHeight(13px)
          +mq_min_lg
            +widthAndHeight(115px, 42px)
            +fontSizeAndLineHeight(16px)
          +mq_min_xl
          //   +widthAndHeight(115px, 42px)
          //   +fontSizeAndLineHeight(16px)

        // 解説ボタン
        .RD_exam_desc_button
          +widthAndHeight(82px, 30px)
          margin-left: 23px
          +fontSizeAndLineHeight(13px)
          +mq_min_sm
            +widthAndHeight(120px, 33px)
            margin-left: 70px
            +fontSizeAndLineHeight(12px)
          +mq_min_md
            +widthAndHeight(90px, 40px)
            margin-left: 30px
            +fontSizeAndLineHeight(13px)
          +mq_min_lg
            +widthAndHeight(142.5px, 42px)
            margin-left: 32.8px
            +fontSizeAndLineHeight(16px)
          +mq_min_xl
            // +widthAndHeight(142.5px, 42px)
            margin-left: 32.4px
            // +fontSizeAndLineHeight(16px)

        // 結果ボタン
        .RD_exam_result_button
          +widthAndHeight(82px, 30px)
          margin-left: 23px
          +fontSizeAndLineHeight(13px)
          +mq_min_sm
            +widthAndHeight(119.03px, 33px)
            margin-left: 60px
            +fontSizeAndLineHeight(12px)
          +mq_min_md
            +widthAndHeight(128px, 40px)
            margin-left: 35.5px
            +fontSizeAndLineHeight(13px)
          +mq_min_lg
            +widthAndHeight(169px, 42px)
            margin-left: 32.8px
            +fontSizeAndLineHeight(16px)
          +mq_min_xl
            // +widthAndHeight(169px, 42px)
            // margin-left: 32.8px
            // +fontSizeAndLineHeight(16px)

      // .RD_button_wrapper

    // .RD_exam_sub_group_wrapper

  // .RD_exam_group_3
  // ↑ 練習版テスト

// .RD_whole_wrapper

// ↑ 2024-01 Re-Designed

</style>
