<template>
  <div class="RD_footer_wrapper" :class="{'RD_home_footer_wrapper': calledBy=='Home', 'RD_result_footer_wrapper': calledBy=='Result'}">

    <template v-if="['Home','Result'].includes(calledBy)">
      <div v-if="showLogo" class="row RD_footer">
        <div class="col-12 text-right">
          <img alt="logo" src="../assets/logo.png">
          <span class="mx-1">株式会社サポートシステム</span>
        </div>
      </div>
    </template>

    <template v-else>
      <div v-if="showLogo" class="row mt-4">
        <div class="col-12 text-right">
          <img alt="logo" src="../assets/logo.png">
          <span class="mx-1">株式会社サポートシステム</span>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
const LogHeader = 'FooterComponent';
export default {
  props: {
    showLogo: { default: true },
    isRD: { default: false }, // 2024-01 Re-Designed
    calledBy: { default: '' }, // Home, Result
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"

// ↓ 2024-01～ Re-Designed

.RD_footer_wrapper
  font-family: $fontFamilyMeiryo

.RD_home_footer_wrapper

  +paddingX(25px)
  +mq_min_sm
    +paddingX(15px)
  +mq_min_md
    +paddingX(25px)
  +mq_min_lg
    +paddingX(50px)
  +mq_min_xl
    +paddingX(210px)

  .row.RD_footer
    margin-top: 1rem // mt-3

    font-size: 10px
    +mq_min_sm
      font-size: 12px
    +mq_min_md
      font-size: 16px
    +mq_min_lg
      // font-size: 16px
    +mq_min_xl
      // font-size: 16px

  // .RD_home_footer_wrapper

.RD_result_footer_wrapper

  .row.RD_footer

    // Y方向の余白は他者に任せる
    +marginY(0)
    +paddingY(0)

    // X方向の余白はXD上の値から -20px する（card-bodyのpadding(=20px)、rowのmargin(=-15px)、colのpadding(=15px)を考慮）
    $adjX: -20px // -(20-15+15)
    +paddingX(29.4px + $adjX)
    +mq_min_sm
      +paddingX(33.0px + $adjX)
    +mq_min_md
      +paddingX(47.6px + $adjX)
    +mq_min_lg
      +paddingX(93.8px + $adjX)
    +mq_min_xl
      +paddingX(187.8px + $adjX)

    font-size: 10px
    +mq_min_sm
      font-size: 13px
    +mq_min_md
      font-size: 14px
    +mq_min_lg
      font-size: 16px
    +mq_min_xl
      // font-size: 16px

  // .row.RD_footer

// .RD_result_footer_wrapper

// ↑ 2024-01～ Re-Designed

</style>
