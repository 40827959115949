<template>
  <div>

    <!-- 登録情報編集画面用ヘッダー -->
    <div v-if="calledBy=='Profile'" class="card-header text-white bg-nav_header-tg">
      <div class="row">
        <div class="col-12 col-lg-6 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
          <div class="header_title">WEBテスト 試験種 {{ examination? examination.exam_name: '' }}<span class="d-none d-sm-inline px-1"></span><br class="d-sm-none" />登録情報編集</div>
        </div>
        <div v-if="student" class="col-12 col-lg-6 text-right">
          <div>{{ student.school.school_name }}</div>
        </div>
      </div>
    </div>

    <!-- 事前確認画面用ヘッダー  -->
    <div v-else-if="calledBy=='Precautions'" class="card-header text-white bg-nav_header-tg">
      <div class="row">
        <div class="col-12 col-lg-7 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
          <div class="header_title">WEBテスト 試験種 {{ examination? examination.exam_name: '' }}<span class="d-none d-sm-inline px-1"></span><br class="d-sm-none" />受験上の注意点</div>
        </div>
        <div v-if="student" class="col-12 col-lg-6 text-right">
          <!-- <div>{{ student.school.school_name }}</div> -->
        </div>
      </div>
    </div>

    <!-- トップページ用ヘッダー -->
    <div v-else-if="calledBy=='Home'"  class="card-header text-white bg-nav_header-tg RD_home_header">
      <div class="row">
        <div class="col-12 col-lg-6 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
          <div class="RD_header_title">WEBテスト 試験種 {{ examination? examination.exam_name: '' }}</div>
        </div>
        <div v-if="student" class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex flex-column justify-content-end align-items-end">
          <div class="RD_header_school">{{ student.school.school_name }}&nbsp;{{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}{{ student.school_department? `${student.school_department.department_name} `: '' }}</div>
          <div class="RD_header_student">{{ student.student_no? `${student.student_no}　`: '' }}{{ student.full_name? `${student.full_name} さん `: '' }}</div>
        </div>
      </div>
    </div>

    <!-- 試験画面用ヘッダー -->
    <div v-else-if="calledBy=='Exam'" class="card-header text-white bg-nav_header-tg">
      <div class="row">
        <div class="col-7 col-sm-8">
          <span class="header_title">{{ examination? examination.exam_name: '' }}　{{ examPartSetCaption }}</span>
        </div>
      </div>
    </div>

    <!-- 解説画面用ヘッダー -->
    <div v-else-if="calledBy=='Desc1'||calledBy=='Desc2'" class="card-header text-white bg-nav_header-tg">
      <div class="row">
        <div class="col-7 col-sm-8">
          <span class="header_title">{{ examination? examination.exam_name: '' }}　{{ examPartSetCaption }}　解説</span>
        </div>
      </div>
    </div>

    <!-- 結果画面（本番＆復習）用ヘッダー -->
    <div v-else-if="calledBy=='Result'" class="card-header text-white bg-nav_header-tg RD_result_header">
      <div class="row d-block d-lg-none RD_result_header_type1"><!-- ３行表示 -->
        <div class="col-12 RD_header_row_1">
          <div class="RD_header_exam">{{ examination? examination.exam_name: '' }}</div>
          <div class="RD_header_exam_part_set">{{ examPartSetResultCaption }}</div>
        </div>
        <div v-if="student" class="col-12 RD_header_row_2">
          <div class="RD_header_school_faculty_department">{{ student.school.school_name }}&nbsp;{{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}{{ student.school_department? `${student.school_department.department_name} `: '' }}</div>
        </div>
        <div v-if="student" class="col-12 RD_header_row_3">
          <div class="RD_header_student">{{ student.student_no? `${student.student_no}　`: '' }}{{ student.full_name? `${student.full_name} さん `: '' }}</div>
        </div>
      </div>
      <div class="row d-none d-lg-block RD_result_header_type2"><!-- ２行表示  -->
        <div class="col-12 RD_header_row_1">
          <div class="RD_header_exam">{{ examination? examination.exam_name: '' }}</div>
          <div v-if="student" class="RD_header_school_faculty_department">{{ student.school.school_name }}&nbsp;{{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}{{ student.school_department? `${student.school_department.department_name} `: '' }}</div>
        </div>
        <div class="col-12 RD_header_row_2">
          <div class="RD_header_exam_part_set">{{ examPartSetResultCaption }}</div>
          <div v-if="student" class="RD_header_student">{{ student.student_no? `${student.student_no}　`: '' }}{{ student.full_name? `${student.full_name} さん `: '' }}</div>
        </div>
      </div>
    </div>

    <!-- 結果画面（練習版）用ヘッダー -->
    <div v-else-if="calledBy=='Result_P'" class="card-header text-white bg-nav_header-tg">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="header_title">{{ examination? examination.exam_name: '' }}　{{ examPartSetResultCaption }}</div>
        </div>
        <div v-if="student" class="col-12 col-lg-6 mt-2 mt-lg-0 d-flex flex-column justify-content-end align-items-end">
          <div class="header_school">{{ student.school.school_name }}&nbsp;{{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}{{ student.school_department? `${student.school_department.department_name} `: '' }}</div>
          <div class="header_student">{{ student.student_no? `${student.student_no}　`: '' }}{{ student.full_name? `${student.full_name} さん `: '' }}</div>
        </div>
      </div>
    </div>

    <div v-if="isDebug" class="debug_str">TG/HeaderComponent calledBy={{ calledBy }} examPartSetId={{ examPartSetId }} currentExamPart.exam_part_name={{ currentExamPart ? currentExamPart.exam_part_name : '' }}</div>

  </div>
</template>

<script>
const LogHeader = 'TG/HeaderComponent';
export default {
  props: {
    calledBy: { default: '' },
    examination: { default: null },
    student: { default: null },
    examPartSetId: { default: '' },
    currentExamPart: { default: null },
    isDebug: { default: false },
  },
  // data() {
  //   console.log(`[${LogHeader}] data() CALLED`);
  //   return {
  //   };
  // },
  // created() {
  //   console.log(`[${LogHeader}] created() CALLED`);
  // },
  // mounted() {
  //   console.log(`[${LogHeader}] mounted() CALLED`);
  // },
  computed: {
    examPartSetCaption() { // Exam, Desc1, Desc2 の場合のみ使われる
      if (this.examPartSetId) {

        let examPartStr = '';
        if (this.currentExamPart) {
          switch (this.currentExamPart.exam_part_name) {
            // 本試験の「総合」や練習版（「検査１」「総合」）のcaseは不要
            case 'Ａタイプ 検査１': examPartStr = '（言語）'; break;
            case 'Ａタイプ 検査２': examPartStr = '（計数）'; break;
            case 'Ｂタイプ 検査１': examPartStr = '（言語）'; break;
            case 'Ｂタイプ 検査２': examPartStr = '（計数）'; break;
          } // switch
        }

        switch (this.examPartSetId) {
          case 'TG_Ａタイプ_本試験':  return `Aタイプ ${examPartStr}`;
          case 'TG_Ａタイプ_復習１':  return `Aタイプ 復習① ${examPartStr}`;
          case 'TG_Ａタイプ_復習２':  return `Aタイプ 復習② ${examPartStr}`;
          case 'TG_Ａタイプ_復習３':  return `Aタイプ 復習③ ${examPartStr}`;
          case 'TG_Ａタイプ_復習４':  return `Aタイプ 復習④ ${examPartStr}`;

          case 'TG_Ｂタイプ_本試験':  return `Bタイプ ${examPartStr}`;
          case 'TG_Ｂタイプ_復習１':  return `Bタイプ 復習① ${examPartStr}`;
          case 'TG_Ｂタイプ_復習２':  return `Bタイプ 復習② ${examPartStr}`;
          case 'TG_Ｂタイプ_復習３':  return `Bタイプ 復習③ ${examPartStr}`;
          case 'TG_Ｂタイプ_復習４':  return `Bタイプ 復習④ ${examPartStr}`;

          case 'TG_練習Ａ_計数１':    return '練習問題Aタイプ（計数１）';
          case 'TG_練習Ａ_計数２':    return '練習問題Aタイプ（計数２）';
          case 'TG_練習Ａ_計数３':    return '練習問題Aタイプ（計数３）';
          case 'TG_練習Ａ_計数４':    return '練習問題Aタイプ（計数４）';
          case 'TG_練習Ａ_言語':      return '練習問題Aタイプ（言語）';
          case 'TG_練習Ｂ_言語計数':  return '練習問題Bタイプ';
        } // switch

      } // if
      return 'ERROR'; // ここに来るのは想定外
    }, // examPartSetCaption()
    examPartSetResultCaption() { // Result の場合のみ使われる
      if (this.examPartSetId) {
        switch (this.examPartSetId) {
          case 'TG_Ａタイプ_本試験':  return 'Aタイプ　　１回目成績';
          case 'TG_Ａタイプ_復習１':  return 'Aタイプ　　２回目成績';
          case 'TG_Ａタイプ_復習２':  return 'Aタイプ　　３回目成績';
          case 'TG_Ａタイプ_復習３':  return 'Aタイプ　　４回目成績';
          case 'TG_Ａタイプ_復習４':  return 'Aタイプ　　５回目成績';

          case 'TG_Ｂタイプ_本試験':  return 'Bタイプ　　１回目成績';
          case 'TG_Ｂタイプ_復習１':  return 'Bタイプ　　２回目成績';
          case 'TG_Ｂタイプ_復習２':  return 'Bタイプ　　３回目成績';
          case 'TG_Ｂタイプ_復習３':  return 'Bタイプ　　４回目成績';
          case 'TG_Ｂタイプ_復習４':  return 'Bタイプ　　５回目成績';

          case 'TG_練習Ａ_計数１':    return '練習問題Aタイプ（計数１）';
          case 'TG_練習Ａ_計数２':    return '練習問題Aタイプ（計数２）';
          case 'TG_練習Ａ_計数３':    return '練習問題Aタイプ（計数３）';
          case 'TG_練習Ａ_計数４':    return '練習問題Aタイプ（計数４）';
          case 'TG_練習Ａ_言語':      return '練習問題Aタイプ（言語）';
          case 'TG_練習Ｂ_言語計数':  return '練習問題Bタイプ';
        } // switch
      } // if
      return 'ERROR'; // ここに来るのは想定外
    }, // examPartSetResultCaption()
  }, // computed
  methods: {
  },
};
</script>

<style lang="sass" scoped>
@import "../../../common/sass/_base"

.header_title
  font-size: 1.0rem
  +mq(min_576)
    font-weight: 600
    &:not(.multi_line)
      font-size: 1.4rem
      letter-spacing: 0.04em
      transform: scale(1, 0.97)
    &.multi_line
      font-size: 1.2rem

.header_school, .header_student
  font-size: 0.9rem
  +mq(min_576)
    font-size: 1.0rem

// ↓ 2024-01 Re-Designed by 「TG1 top画面 Bootstrap 4 Grid Template - All 5 Sizes（240127）.xd」

// トップページ用ヘッダー
.RD_home_header
  font-family: $fontFamilyMeiryo

  // ↓ 全体の左右余白設定 ※微調整は各クラスで行う
  +paddingX(20px, 30px)
  +mq_min_sm
    +paddingX(44px, 57px)
  +mq_min_md
    +paddingX(46px, 42px)
  +mq_min_lg
    +paddingX(41px, 67px)
  +mq_min_xl
    +paddingX(177px, 179px)
  // ↑

  .RD_header_title
    font-size: 20px
    font-weight: bold
    +mq_min_sm
      // font-size: 20px
    +mq_min_md
      font-size: 24px
    +mq_min_lg
      font-size: 28px
    +mq_min_xl
      // font-size: 28px

  .RD_header_school
    font-size: 13px
    font-weight: normal
    +mq_min_sm
      font-size: 15px
    +mq_min_md
      font-size: 16px
    +mq_min_lg
      font-size: 18px
    +mq_min_xl
      font-size: 20px

  .RD_header_student
    font-size: 13px
    font-weight: normal
    +mq_min_sm
      font-size: 15px
    +mq_min_md
      font-size: 16px
    +mq_min_lg
      font-size: 18px
    +mq_min_xl
      font-size: 20px

// .RD_home_header

// ↑ 2024-01 Re-Designed

// ↓ 2024-02 Re-Designed by 「TG１ 結果画面 Bootstrap 4 Grid Template - All 5 Sizes.xd」 ※ 但し、実質的にはTAMAを流用

// 結果画面用ヘッダー
.RD_result_header
  font-family: $fontFamilyMeiryo

  // ↓ 全体の左右余白設定 ※微調整は各クラスで行う
  +paddingX(14px, 30px)
  +mq_min_sm
    +paddingX(14px, 57px)
  +mq_min_md
    +paddingX(27px, 42px)
  +mq_min_lg
    +paddingX(63px, 67px)
  +mq_min_xl
    +paddingX(80px, 179px)
  // ↑

  // ３行表示 ※xs,sm,md専用
  .RD_result_header_type1
    // line-height:

    .RD_header_row_1

      .RD_header_exam
        display: inline-block
        font-size: 20px
        font-weight: bold
        +mq_min_sm
          // font-size: 20px
        +mq_min_md
          font-size: 28px

      .RD_header_exam_part_set
        display: inline-block
        margin-left: 53px
        font-size: 20px
        font-weight: bold
        +mq_min_sm
          // margin-left: 53px
          // font-size: 20px
        +mq_min_md
          margin-left: 24px
          font-size: 28px

    // .RD_header_row_1

    .RD_header_row_2
      text-align: right

      .RD_header_school_faculty_department
        font-size: 13px
        font-weight: normal
        +mq_min_sm
          font-size: 15px
        +mq_min_md
          font-size: 16px

    // .RD_header_row_2

    .RD_header_row_3
      text-align: right

      .RD_header_student
        font-size: 13px
        font-weight: normal
        +mq_min_sm
          font-size: 15px
        +mq_min_md
          font-size: 16px

    // .RD_header_row_3

  // .RD_result_header_type1

  // ２行表示 ※lg,xl専用
  .RD_result_header_type2
    line-height: 1.2

    .RD_header_row_1
      display: flex
      justify-content: space-between
      align-items: center

      .RD_header_exam
        display: inline-block
        +mq_min_lg
          font-size: 28px
          font-weight: bold
        +mq_min_xl
          // font-size: 28px

      .RD_header_school_faculty_department
        display: inline-block
        +mq_min_lg
          font-size: 18px
          font-weight: normal
        +mq_min_xl
          font-size: 20px

    // .RD_header_row_1

    .RD_header_row_2
      display: flex
      justify-content: space-between
      align-items: center

      .RD_header_exam_part_set
        display: inline-block
        +mq_min_lg
          font-size: 28px
          font-weight: bold
        +mq_min_xl
          // font-size: 28px

      .RD_header_student
        display: inline-block
        +mq_min_lg
          font-size: 18px
          font-weight: normal
        +mq_min_xl
          font-size: 20px

    // .RD_header_row_2

  // .RD_result_header_type2

// .RD_result_header

// ↑ 2024-02 Re-Designed

</style>
